import React, { useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Formik, Field, FieldArray } from 'formik';
import {
  Select, Button, Col, Row,
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import * as Yup from 'yup';

import FieldCheckbox from '../common/components/formFields/FieldCheckbox';
import InputField from '../common/components/formFields/InputField';
import { ServicesGroupsContext } from '../servicesGroups/Provider';

const { Option } = Select;

const Form = ({
  edit, loading, initialValues, addService, editService,
}) => {
  const history = useHistory();
  const { servicesGroups, fetchServicesGroups } = useContext(ServicesGroupsContext);
  const formSchema = Yup.object().shape({
    name: Yup.object().shape({
      ru: Yup.string().required(),
      uk: Yup.string().required(),
    }),
    key: Yup.string().required(),
    groupId: Yup.string().required(),
  });

  useEffect(() => {
    fetchServicesGroups({ limit: 0 });
  }, []);

  function renderFieldArray(arrayHelpers, fields) {
    return (
      <>
        <Row gutter={[4, 16]}>
          <Col>
            <span style={{ verticalAlign: 'middle' }}>Возможное значение</span>
          </Col>

          <Col>
            <Button type="primary" onClick={() => arrayHelpers.insert(0, '')}>Добавить еще</Button>
          </Col>
        </Row>

        {fields.map((field, index) => (
          <Row gutter={[24, 16]} key={index} className="inlineFieldsRowWrap">
            <Col>
              <Row gutter={4}>
                <Col>
                  <Field name={`possibleValues.${index}`} component={InputField} type="text" classes="form-control" />
                </Col>

                <Col>
                  <Button type="danger" icon={<DeleteOutlined />} onClick={() => arrayHelpers.remove(index)} />
                </Col>
              </Row>
            </Col>
          </Row>
        ))}
      </>
    );
  }

  function onSubmit(values) {
    if (edit) {
      return editService(values.id, values, () => history.push('/services'));
    }

    return addService(values, () => history.push('/services'));
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={formSchema}
      validateOnChange={false}
    >
      {({
        values,
        handleSubmit,
        setFieldValue,
        errors,
        touched,
      }) => (
        <Row>
          <Col span={20} offset={2}>
            <form onSubmit={handleSubmit}>
              <Row gutter={[8, 16]}>
                <Col>
                  <Col>
                    <Field
                      type="text"
                      name="name.ru"
                      addonBefore="Название сервиса (рус)"
                      component={InputField}
                      className={errors.name && errors.name.ru && touched.name.ru && 'ant-form-item-has-error'}
                    />
                  </Col>
                </Col>

                <Col>
                  <Col>
                    <Field
                      type="text"
                      name="name.uk"
                      addonBefore="Название сервиса (укр)"
                      component={InputField}
                      className={errors.name && errors.name.uk && touched.name.uk && 'ant-form-item-has-error'}
                    />
                  </Col>
                </Col>

                <Col>
                  <Field
                    type="text"
                    name="key"
                    addonBefore="Ключ сервиса"
                    component={InputField}
                    className={errors.key && touched.key && 'ant-form-item-has-error'}
                  />
                </Col>
              </Row>

              <Row>
                <Col className={errors.groupId && touched.groupId && 'ant-form-item-has-error'}>
                  <Select
                    name="groupId"
                    onChange={(value) => setFieldValue('groupId', value)}
                    style={{ width: 200 }}
                    placeholder="Группа сервиса"
                    defaultValue={values.groupId}
                  >
                    {servicesGroups.map((serviceGroup) => (
                      <Option value={serviceGroup.id} key={serviceGroup.id}>{serviceGroup.name.ru}</Option>
                    ))}
                  </Select>
                </Col>

                <Col>
                  <Field
                    name="filter"
                    component={FieldCheckbox}
                    type="checkbox"
                    addonBefore="Отображать в фильтрах"
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  <FieldArray
                    name="possibleValues"
                    render={(arrayHelpers) => renderFieldArray(arrayHelpers, values.possibleValues)}
                  />
                </Col>
              </Row>

              <Button type="primary" htmlType="submit" disabled={edit && loading}>
                {edit ? 'Сохранить' : 'Добавить сервис'}
              </Button>
            </form>
          </Col>
        </Row>
      )}
    </Formik>
  );
};

Form.defaultProps = {
  initialValues: {
    name: {
      ru: '',
      uk: '',
    },
    key: '',
    groupId: undefined,
    filter: false,
    possibleValues: [],
  },
  edit: false,
  loading: false,
  addService: () => { },
  editService: () => { },
};

Form.propTypes = {
  edit: PropTypes.bool,
  loading: PropTypes.bool,
  initialValues: PropTypes.shape({}),
  addService: PropTypes.func,
  editService: PropTypes.func,
};

export default Form;
