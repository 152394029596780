import React, { useContext } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Table, Tag, Popconfirm } from 'antd';
import {
  EditOutlined, DeleteOutlined, SettingOutlined, CheckOutlined, MinusOutlined, PoweroffOutlined, FireOutlined,
} from '@ant-design/icons';

import NoData from '../common/components/NoData';
import ActionButton from '../common/components/ActionButton';
import { CountriesContext } from './Provider';

const CountriesTable = () => {
  const history = useHistory();
  const {
    countries, editCountry, deleteCountry,
  } = useContext(CountriesContext);

  function renderMakeDefaultToggleButton(...args) {
    const [, rowItem] = args;
    const { default: isDefault, disabled } = rowItem;

    const icon = isDefault ? <CheckOutlined /> : <MinusOutlined />;
    const confirmMessage = isDefault ? '' : 'Вы уверены, что хотите сделать эту страну страной по умолчанию?';
    const tooltip = disabled
      ? 'Пожалуйста, активируйте страну, чтобы установить её по умолчанию'
      : 'Сделать страну по умолчанию?';

    return (
      <Popconfirm
        okText="Да"
        cancelText="Нет"
        placement="leftTop"
        title={confirmMessage}
        onConfirm={() => editCountry(rowItem.id, { ...rowItem, default: !isDefault })}
      >
        <ActionButton icon={icon} tooltip={tooltip} disabled={isDefault || disabled} />
      </Popconfirm>
    );
  }

  function renderPopularToggleButton(...args) {
    const [, rowItem] = args;
    const { popular } = rowItem;
    const icon = popular ? <CheckOutlined /> : <MinusOutlined />;
    const tooltip = popular ? 'Сделать не популярной' : 'Сделать популярной';

    return (
      <ActionButton
        icon={icon}
        tooltip={tooltip}
        onClick={() => editCountry(rowItem.id, { ...rowItem, popular: !popular })}
      />
    );
  }

  function renderDisabledToggleButton(...args) {
    const [, rowItem] = args;
    const { disabled, default: isDefault } = rowItem;

    const icon = disabled ? <MinusOutlined /> : <CheckOutlined />;
    let tooltip;

    if (isDefault) {
      tooltip = 'Пожалуйста, выберите другую страну по умолчанию перед отключением этой страны';
    } else if (disabled) {
      tooltip = 'Включить';
    } else {
      tooltip = 'Выключить';
    }

    return (
      <ActionButton
        icon={icon}
        tooltip={tooltip}
        disabled={isDefault}
        onClick={() => editCountry(rowItem.id, { ...rowItem, disabled: !disabled })}
      />
    );
  }

  function renderEditButton(...args) {
    const [, rowItem] = args;

    return (
      <ActionButton
        type="primary"
        icon={<EditOutlined />}
        tooltip="Править"
        className="editAction"
        onClick={() => history.push(`/countries/${rowItem.id}/edit`)}
      />
    );
  }

  function renderDeleteButton(...args) {
    const [, rowItem] = args;
    const { default: isDefault, id } = rowItem;
    const tooltip = isDefault
      ? 'Пожалуйста, выберите другую страну по умолчанию перед удаление этой страны'
      : 'Удалить';
    const deleteConfirm = 'Вы уверены, что хотите удалить эту страну?';

    return (
      <Popconfirm
        okText="Да"
        cancelText="Нет"
        placement="leftTop"
        title={deleteConfirm}
        onConfirm={() => deleteCountry(id)}
      >
        <ActionButton
          type="danger"
          icon={<DeleteOutlined />}
          tooltip={tooltip}
          className="deleteAction"
          disabled={isDefault}
        />
      </Popconfirm>
    );
  }

  function renderTitleCell(title, item) {
    return (
      <>
        <Link to={{ pathname: '/cities', state: { countryId: item.id } }}>{title.ru}</Link>
        {item.default ? <Tag color="#87d068" onClick={() => { }}>Default country</Tag> : null}
      </>
    );
  }

  const columns = [
    { dataIndex: 'name', title: 'Название', render: renderTitleCell },
    { dataIndex: 'id', title: 'ID' },
    { dataIndex: 'currency', title: 'Валюта' },
    {
      width: 50, title: () => <SettingOutlined />, dataIndex: '', key: 'default', render: renderMakeDefaultToggleButton,
    },
    {
      width: 50, title: () => <FireOutlined />, dataIndex: '', key: 'popular', render: renderPopularToggleButton,
    },
    {
      width: 50, title: () => <PoweroffOutlined />, dataIndex: '', key: 'toggle', render: renderDisabledToggleButton,
    },
    {
      width: 50, title: () => <EditOutlined />, dataIndex: '', key: 'EDIT', render: renderEditButton,
    },
    {
      width: 50, title: () => <DeleteOutlined />, dataIndex: '', key: 'DELETE', render: renderDeleteButton,
    },
  ];

  const tableHorizontalScroll = columns.reduce((sum, col) => (sum + col.width || 0), 0);

  if (!countries.length) return <NoData />;

  return (
    <Table
      columns={columns}
      rowKey="id"
      dataSource={countries}
      pagination={false}
      scroll={{ x: tableHorizontalScroll }}
    />
  );
};

export default CountriesTable;
