import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Spin, PageHeader } from 'antd';

import { ServicesContext } from './Provider';
import Form from './Form';

const EditView = () => {
  const { id } = useParams();
  const {
    loading, currentService, getService, editService,
  } = useContext(ServicesContext);

  useEffect(() => {
    getService(id);
  }, []);

  if (loading || !currentService) {
    return <Spin tip="Loading..." size="large" />;
  }

  return (
    <div>
      <PageHeader className="site-page-header" title="Редактировать сервис" />
      <Form
        edit
        loading={loading}
        initialValues={currentService}
        editService={editService}
      />
    </div>
  );
};

export default EditView;
