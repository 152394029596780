import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Spin, PageHeader, Pagination } from 'antd';

import { CitiesContext } from './Provider';
import CitiesTable from './Table';
import CitiesTopPanel from './TopPanel';

const Cities = () => {
  const location = useLocation();
  const {
    count, loading, searchParams, setSearchParams, fetchCitiesWithParentsNames,
  } = useContext(CitiesContext);

  function onChangePagination(page, pageSize) {
    const skip = (page - 1) * pageSize;

    setSearchParams({ ...searchParams, skip });
  }

  useEffect(() => {
    if (location.state && location.state.countryId) {
      setSearchParams({ ...searchParams, countryId: location.state.countryId });
    }
  }, []);

  useEffect(() => {
    fetchCitiesWithParentsNames();
  }, [searchParams]);

  if (loading) {
    return <Spin tip="Loading..." size="large" />;
  }

  return (
    <div style={{ margin: 30 }}>
      <PageHeader className="site-page-header" title="Города" />
      <CitiesTopPanel />
      <CitiesTable />
      <Pagination
        showSizeChanger={false}
        current={(searchParams.skip / 20) + 1 || 1}
        defaultPageSize={20}
        total={count}
        onChange={onChangePagination}
      />
    </div>
  );
};

export default Cities;
