/* eslint-disable react/prop-types */
import React from 'react';
import { Input } from 'antd';

const InputField = ({ field, ...other }) => (
  <Input
    {...field}
    {...other}
  />
);

export default InputField;
