import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { NotificationManager } from 'react-notifications';

import {
  listServicesApi, getServiceApi, addServiceApi, editServiceApi, deleteServiceApi,
} from './api';
import getSearchParams from '../utils/getSearchParams';
import { ServicesGroupsContext } from '../servicesGroups/Provider';
import ParentsSearchParams from '../utils/ParentsSearchParams';
import getErrorMessage from '../utils/getErrorMessage';

const ServicesContext = React.createContext();

const ServicesProvider = ({ children }) => {
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useState({});
  const [services, setServices] = useState([]);
  const [currentService, setCurrentService] = useState(null);

  const { clearFetchServicesGroups } = useContext(ServicesGroupsContext);

  const clearFetchServices = (params) => listServicesApi(params);

  const fetchServices = async (params = null) => {
    const finalParams = params || getSearchParams(searchParams);

    try {
      const servicesData = await listServicesApi(finalParams);

      setServices(servicesData.data.items);
      setCount(servicesData.data.count);
      setLoading(false);
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  const fetchServicesWithParentsNames = async (params = null) => {
    const finalParams = params || getSearchParams(searchParams);

    try {
      const servicesDate = await listServicesApi(finalParams);
      const parentsSearchParams = new ParentsSearchParams(servicesDate.data.items, ['groupId'])
        .getParentsSearchParams();
      const servicesGroupsData = await clearFetchServicesGroups(parentsSearchParams.groupId);
      const servicesGroupsMap = ParentsSearchParams.getParentMap(servicesGroupsData.data.items);

      const servicesWithParentsNames = servicesDate.data.items.map(
        (service) => ({ ...service, groupName: servicesGroupsMap.get(service.groupId) }),
      );

      setServices(servicesWithParentsNames);
      setCount(servicesDate.data.count);
      setLoading(false);
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  const getService = async (id) => {
    try {
      setCurrentService(null);

      const servicesGroup = await getServiceApi(id);

      setLoading(false);
      setCurrentService(servicesGroup.data);
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  const addService = async (data, callback) => {
    try {
      await addServiceApi(data);
      setLoading(false);

      if (callback && typeof callback === 'function') {
        callback();
      }
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  const editService = async (id, data, callback) => {
    try {
      const servicesGroup = await editServiceApi(id, data);
      const index = services.findIndex((item) => item.id === servicesGroup.data.id);
      const newServices = [...services];

      newServices.splice(index, 1, servicesGroup.data);

      setServices(newServices);
      setLoading(false);

      if (callback && typeof callback === 'function') {
        callback();
      }
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  const deleteService = async (id) => {
    try {
      await deleteServiceApi(id);
      await fetchServices();
      setLoading(false);
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  return (
    <ServicesContext.Provider value={{
      count,
      loading,
      searchParams,
      services,
      currentService,
      setSearchParams,
      fetchServices,
      clearFetchServices,
      fetchServicesWithParentsNames,
      getService,
      addService,
      editService,
      deleteService,
    }}
    >
      {children}
    </ServicesContext.Provider>
  );
};

ServicesProvider.propTypes = {
  children: PropTypes.object.isRequired,
};

export { ServicesProvider, ServicesContext };
