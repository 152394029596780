import React, {
  useEffect, useState, useContext,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Tabs } from 'antd';
import _groupBy from 'lodash.groupby';
import _omit from 'lodash.omit';
import * as Yup from 'yup';

import Hotel from './forms/Hotel';
import Descriptions from './forms/Descriptions';
import Links from './forms/Links';
import Images from './forms/Images';
import Attributes from './forms/Attributes';
import Rooms from './forms/Rooms';
import Reviews from './forms/Reviews';
import { HotelsContext } from './Provider';
import { CountriesContext } from '../countries/Provider';
import { CitiesContext } from '../cities/Provider';
import { StarsContext } from '../stars/Provider';
import { ServicesGroupsContext } from '../servicesGroups/Provider';
import { ServicesContext } from '../services/Provider';
import { RoomsContext } from '../rooms/Provider';
import { ReviewsContext } from '../reviews/Provider';
import formatImages from '../utils/formatImages';

const { TabPane } = Tabs;

const Form = ({ initialValues, operatorKeys }) => {
  const history = useHistory();
  const { id: hotelId } = useParams();
  const [operator, setOperator] = useState(null);
  const [id, setId] = useState(null);
  const [otpuskConfirmModal, setOtpuskConfirmModal] = useState({
    disabledSyncButton: false,
    otpuskId: null,
    visible: false,
    confirmLoading: false,
    synced: false,
  });
  const formSchema = Yup.object().shape({
    name: Yup.string().required(),
  });

  const {
    loading, syncHotelWithTripAdvisor, editHotel, syncHotelWithOtpusk,
  } = useContext(HotelsContext);
  const { countries, fetchCountries } = useContext(CountriesContext);
  const { cities, fetchCities } = useContext(CitiesContext);
  const { stars, fetchStars } = useContext(StarsContext);
  const { servicesGroups, fetchServicesGroups } = useContext(ServicesGroupsContext);
  const { services, fetchServices } = useContext(ServicesContext);
  const { rooms, fetchRooms, deleteRoom } = useContext(RoomsContext);
  const { reviews, fetchReviews, deleteReview } = useContext(ReviewsContext);

  const prepareVendorsIds = (links) => {
    const grouped = _groupBy(links, 'operator');
    const defaultLinks = operatorKeys.reduce((acc, key) => {
      acc[key] = [];

      return acc;
    }, {});

    const preparedLinks = Object.keys(grouped).reduce((acc, key) => {
      acc[key] = grouped[key].map((el) => parseInt(el.id, 10));

      return acc;
    }, defaultLinks);

    return preparedLinks;
  };

  useEffect(() => {
    fetchStars({ limit: 0 });
    fetchCountries({ limit: 0 });
    fetchRooms({ limit: 0, hotelId });
    fetchReviews({ limit: 0, hotelId });

    if (initialValues.countryId) {
      fetchCities({ countryId: initialValues.countryId, limit: 0 });
    }

    if (initialValues.services) {
      setOtpuskConfirmModal({
        ...otpuskConfirmModal,
        disabledSyncButton: true,
      });
    }
  }, []);

  useEffect(() => {
    if (otpuskConfirmModal.synced) {
      window.location.reload();
    }
  }, [otpuskConfirmModal.synced]);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values) => {
        const links = prepareVendorsIds(values.links);
        const newValues = {
          ..._omit(values, 'links'),
          ...links,
        };

        newValues.images = formatImages(newValues.images);

        return editHotel(values.id, newValues, () => history.push('/hotels'));
      }}
      validationSchema={formSchema}
      validateOnChange={false}
    >
      {({
        values,
        errors,
        touched,
        handleSubmit,
        setFieldValue,
      }) => (
        <Tabs defaultActiveKey="1">
          <TabPane tab="Общая информация" key="1">
            <Hotel
              loading={loading}
              history={history}
              values={values}
              errors={errors}
              touched={touched}
              countries={countries}
              cities={cities}
              stars={stars}
              setFieldValue={setFieldValue}
              handleSubmit={handleSubmit}
              fetchCities={fetchCities}
              syncHotelWithTripAdvisor={syncHotelWithTripAdvisor}
            />
          </TabPane>

          <TabPane tab="Описание отеля" key="2">
            <Descriptions
              loading={loading}
              history={history}
              handleSubmit={handleSubmit}
            />
          </TabPane>

          <TabPane tab="Атрибуты" key="3">
            <Attributes
              loading={loading}
              history={history}
              values={values}
              servicesGroups={servicesGroups}
              services={services}
              setFieldValue={setFieldValue}
              handleSubmit={handleSubmit}
              fetchServicesGroups={fetchServicesGroups}
              fetchServices={fetchServices}
            />
          </TabPane>

          <TabPane tab="Изображения" key="4">
            <Images
              loading={loading}
              history={history}
              values={values}
              setFieldValue={setFieldValue}
              handleSubmit={handleSubmit}
            />
          </TabPane>

          <TabPane tab="Линковки" key="5">
            <Links
              loading={loading}
              history={history}
              values={values}
              operator={operator}
              id={id}
              operatorKeys={operatorKeys}
              setId={setId}
              setOperator={setOperator}
              handleSubmit={handleSubmit}
              syncHotelWithOtpusk={syncHotelWithOtpusk}
              otpuskConfirmModal={otpuskConfirmModal}
              setOtpuskConfirmModal={setOtpuskConfirmModal}
            />
          </TabPane>

          <TabPane tab="Номера" key="6">
            <Rooms
              hotelId={hotelId}
              rooms={rooms}
              deleteRoom={deleteRoom}
            />
          </TabPane>

          <TabPane tab="Отзывы" key="7">
            <Reviews
              hotelId={hotelId}
              reviews={reviews}
              deleteReview={deleteReview}
            />
          </TabPane>
        </Tabs>
      )}
    </Formik>
  );
};

export default Form;

Form.defaultProps = {
  initialValues: {
    name: '',
    countryId: '',
    cityId: '',
    default: false,
    disabled: false,
    andromedaId: [],
  },
  operatorKeys: [],
};

Form.propTypes = {
  initialValues: PropTypes.shape({
    services: PropTypes.shape({}),
    countryId: PropTypes.string,
  }),
  operatorKeys: PropTypes.array,
};
