import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import {
  Input, Select, Button, Col, Row,
} from 'antd';
import { SearchOutlined, CloseCircleOutlined } from '@ant-design/icons';

import { CitiesContext } from './Provider';
import { CountriesContext } from '../countries/Provider';
import { RegionsContext } from '../regions/Provider';

const { Option } = Select;

const TopPanel = () => {
  const history = useHistory();
  const { setSearchParams } = useContext(CitiesContext);
  const { countries, fetchCountries } = useContext(CountriesContext);
  const { regions, fetchRegions } = useContext(RegionsContext);

  useEffect(() => {
    fetchCountries({ limit: 0 });
    fetchRegions({ limit: 0 });
  }, []);

  return (
    <div>
      <Formik
        initialValues={{}}
        onSubmit={(values) => setSearchParams(values)}
        onReset={(values) => setSearchParams(values)}
      >
        {({
          values, handleChange, handleBlur, handleSubmit, setFieldValue, resetForm,
        }) => (
          <form onSubmit={handleSubmit} onReset={resetForm}>
            <Row gutter={[8, 8]}>
              <Col flex="1 1 500px">
                <Row justify="start">
                  <Col span={4}>
                    <Input
                      type="text"
                      name="search"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.search}
                      placeholder="Город"
                    />
                  </Col>

                  <Col span={4}>
                    <Select
                      name="disabled"
                      onChange={(value) => setFieldValue('disabled', value)}
                      onBlur={handleBlur}
                      defaultValue={values.disabled}
                      style={{ width: 100 }}
                      placeholder="Включен"
                    >
                      <Option value="">Все</Option>
                      <Option value="false">Включен</Option>
                      <Option value="true">Выключен</Option>
                    </Select>
                  </Col>

                  <Col span={4}>
                    <Select
                      name="popular"
                      onChange={(value) => setFieldValue('popular', value)}
                      onBlur={handleBlur}
                      defaultValue={values.popular}
                      style={{ width: 150 }}
                      placeholder="Популярный"
                    >
                      <Option value="">Все</Option>
                      <Option value="true">Популярный</Option>
                      <Option value="false">Не популярный</Option>
                    </Select>
                  </Col>

                  <Col span={4}>
                    <Select
                      name="countryId"
                      onChange={(value) => {
                        setFieldValue('countryId', value);
                      }}
                      onBlur={handleBlur}
                      placeholder="Страна"
                      style={{ width: 150 }}
                    >
                      {countries.map((country) => (
                        <Option value={country.id} key={country.id}>{country.name.ru}</Option>
                      ))}
                    </Select>
                  </Col>

                  <Col span={4}>
                    <Select
                      name="regionId"
                      onChange={(value) => {
                        setFieldValue('regionId', value);
                      }}
                      onBlur={handleBlur}
                      placeholder="Регион"
                      style={{ width: 150 }}
                    >
                      {regions.map((region) => (
                        <Option value={region.id} key={region.id}>{region.name.ru}</Option>
                      ))}
                    </Select>
                  </Col>

                  <Col span={2}>
                    <Button htmlType="submit" type="primary" icon={<SearchOutlined />}>
                      Найти
                    </Button>
                  </Col>

                  <Col span={2}>
                    <Button htmlType="reset" type="primary" icon={<CloseCircleOutlined />}>
                      Очистить
                    </Button>
                  </Col>
                </Row>
              </Col>

              <Col flex="0 1 100px">
                <Button type="primary" onClick={() => history.push('/cities/add')}>
                  Добавить город
                </Button>
              </Col>
            </Row>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default TopPanel;
