import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Button, Select } from 'antd';
import _set from 'lodash.set';
import _get from 'lodash.get';

const { Option } = Select;

const AddAttributeForm = ({
  servicesValue,
  servicesGroups,
  services,
  setFieldValue,
}) => (
  <Formik
    enableReinitialize
    onSubmit={(subValues) => {
      const group = servicesGroups.find((element) => element.id === subValues.group);
      const service = services.find((element) => element.id === subValues.service);
      const updatedServices = _set(servicesValue, `${[group.key]}.${service.key}`, subValues.value);

      setFieldValue('services', updatedServices);
    }}
    initialValues={{
      group: '',
    }}
  >
    {({
      values: subFormValues,
      handleSubmit: subHandleSubmit,
      setFieldValue: subSetFieldValue,
    }) => (
      <form onSubmit={subHandleSubmit}>
        <Select
          onChange={(value) => {
            const found = servicesGroups.find((el) => el.id === value);

            subSetFieldValue('group', found.id);
          }}
          placeholder="Группа"
          style={{ width: 180 }}
        >
          {servicesGroups.map((el) => (
            <Option value={el.id} key={el.id}>{el.name.ru}</Option>
          ))}
        </Select>

        <Select
          onChange={(value) => {
            const found = services.find((el) => el.id === value);

            subSetFieldValue('service', found.id);
          }}
          style={{ width: 180 }}
          placeholder="Сервис"
        >
          {services.filter((el) => el.groupId === subFormValues.group).map((el) => (
            <Option value={el.id} key={el.id}>{el.name.ru}</Option>
          ))}
        </Select>

        <Select
          onChange={(value) => subSetFieldValue('value', value)}
          style={{ width: 180 }}
          placeholder="Значение"
        >
          {_get(services.find((el) => el.id === subFormValues.service), 'possibleValues', []).map((el) => (
            <Option value={el} key={el}>{el}</Option>
          ))}
        </Select>

        <Button type="primary" htmlType="submit">
          Добавить услугу
        </Button>
      </form>
    )}
  </Formik>
);

export default AddAttributeForm;

AddAttributeForm.propTypes = {
  servicesValue: PropTypes.shape({}).isRequired,
  servicesGroups: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  services: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setFieldValue: PropTypes.func.isRequired,
};
