import { isSupported, CookieStorage, MemoryStorage } from 'local-storage-fallback';

const isTestEnv = () => global.process && process.env.NODE_ENV === 'test';

const getSessionStorage = () => {
  if (isTestEnv()) {
    return new MemoryStorage();
  }
  if (isSupported('sessionStorage')) {
    return global.window.sessionStorage;
  }
  if (isSupported('cookieStorage')) {
    return new CookieStorage();
  }

  return new MemoryStorage();
};

const getLocalStorage = () => {
  if (isTestEnv()) {
    return new MemoryStorage();
  }
  if (isSupported('localStorage')) {
    return global.window.localStorage;
  }
  if (isSupported('cookieStorage')) {
    return new CookieStorage();
  }

  return new MemoryStorage();
};

const localStorage = getLocalStorage();
const sessionStorage = getSessionStorage();

export const getStorageValue = (key) => {
  const sessionStorageValue = sessionStorage.getItem(key) || undefined;
  const localStorageValue = localStorage.getItem(key) || undefined;

  if (localStorageValue) {
    if (sessionStorageValue !== localStorageValue) {
      sessionStorage.setItem(key, localStorageValue);
    }

    return localStorageValue;
  }

  return sessionStorageValue;
};

export const setStorageValue = (key, value, long = false) => {
  sessionStorage.setItem(key, value);
  if (long) {
    localStorage.setItem(key, value);
  } else {
    localStorage.removeItem(key);
  }
};

export const removeFromStorage = (key) => {
  sessionStorage.removeItem(key);
  localStorage.removeItem(key);
};
