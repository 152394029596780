import React, { useContext } from 'react';
import { PageHeader } from 'antd';

import { CitiesContext } from './Provider';
import Form from './Form';

const AddView = () => {
  const { loading, addCity } = useContext(CitiesContext);

  return (
    <div>
      <PageHeader className="site-page-header" title="Добавить город" />
      <Form loading={loading} addCity={addCity} />
    </div>
  );
};

export default AddView;
