import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FieldArray, Formik, Field } from 'formik';
import {
  Select, Button, Col, Row, Popconfirm,
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import * as Yup from 'yup';

import InputField from '../common/components/formFields/InputField';

const { Option } = Select;

const Form = ({
  initialValues, loading, edit, addDepartureCity, editDepartureCity,
}) => {
  const history = useHistory();
  const formSchema = Yup.object().shape({
    name: Yup.object().shape({
      ru: Yup.string().required(),
      uk: Yup.string().required(),
    }),
  });

  function renderFieldArray(arrayHelpers, fields) {
    return (
      <>
        <Row gutter={[4, 16]}>
          <Col>
            <span style={{ verticalAlign: 'middle' }}>AndromedaId*</span>
          </Col>

          <Col>
            <Button type="primary" onClick={() => arrayHelpers.insert(0, '')}>Добавить еще</Button>
          </Col>
        </Row>

        {fields.map((field, index) => (
          <Row gutter={[24, 16]} key={index} className="inlineFieldsRowWrap">
            <Col>
              <Row gutter={4}>
                <Col>
                  <Field name={`andromedaId.${index}`} component={InputField} type="number" classes="form-control" />
                </Col>

                <Col>
                  <Popconfirm
                    okText="Yes"
                    cancelText="No"
                    placement="leftTop"
                    onConfirm={() => arrayHelpers.remove(index)}
                    title="Вы точно уверены что хотите удалить этот andromedaId?"
                  >
                    <Button type="danger" icon={<DeleteOutlined />} />
                  </Popconfirm>
                </Col>
              </Row>
            </Col>
          </Row>
        ))}
      </>
    );
  }

  function onSubmit(values) {
    if (edit) {
      return editDepartureCity(values.id, values, () => history.push('/departure-cities'));
    }

    return addDepartureCity(values, () => history.push('/departure-cities'));
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={formSchema}
      validateOnChange={false}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }) => (
        <Row>
          <Col span={20} offset={2}>
            <form onSubmit={handleSubmit}>
              <Row gutter={[8, 16]}>
                <Col>
                  <Field
                    type="text"
                    name="name.ru"
                    addonBefore="Название города отправления (рус)"
                    component={InputField}
                    className={errors.name && errors.name.ru && touched.name.ru && 'ant-form-item-has-error'}
                  />
                </Col>

                <Col>
                  <Field
                    type="text"
                    name="name.uk"
                    addonBefore="Название города отправления (укр)"
                    component={InputField}
                    className={errors.name && errors.name.uk && touched.name.uk && 'ant-form-item-has-error'}
                  />
                </Col>

                <Col>
                  <Select
                    name="disabled"
                    onChange={(value) => setFieldValue('disabled', value)}
                    onBlur={handleBlur}
                    defaultValue={values.disabled}
                    style={{ width: 120 }}
                  >
                    <Option value={false}>Включен</Option>
                    <Option value>Выключен</Option>
                  </Select>
                </Col>
              </Row>

              <Row gutter={[8, 16]}>
                <Col>
                  <Select
                    name="default"
                    onChange={(value) => setFieldValue('default', value)}
                    onBlur={handleBlur}
                    defaultValue={values.default}
                    style={{ width: 170 }}
                  >
                    <Option value>По умолчанию</Option>
                    <Option value={false}>Не по умолчанию</Option>
                  </Select>
                </Col>
              </Row>

              <Row gutter={[8, 16]}>
                <Col>
                  <FieldArray
                    name="andromedaId"
                    render={(arrayHelpers) => renderFieldArray(arrayHelpers, values.andromedaId)}
                  />
                </Col>
              </Row>

              <Button type="primary" htmlType="submit" disabled={edit && loading}>
                {edit ? 'Сохранить' : 'Добавить город отправления'}
              </Button>
            </form>
          </Col>
        </Row>
      )}
    </Formik>
  );
};

export default Form;

Form.defaultProps = {
  initialValues: {
    disabled: false,
    name: {
      ru: '',
      uk: '',
    },
    default: false,
    andromedaId: [],
  },
  edit: false,
  loading: false,
  addDepartureCity: () => {},
  editDepartureCity: () => {},
};

Form.propTypes = {
  initialValues: PropTypes.shape({}),
  edit: PropTypes.bool,
  loading: PropTypes.bool,
  addDepartureCity: PropTypes.func,
  editDepartureCity: PropTypes.func,
};
