import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import {
  Input, Select, Button, Col, Row,
} from 'antd';
import { SearchOutlined, CloseCircleOutlined } from '@ant-design/icons';

import { DepartureCitiesContext } from './Provider';

const { Option } = Select;

const TopPanel = () => {
  const history = useHistory();
  const { setSearchParams } = useContext(DepartureCitiesContext);

  return (
    <div>
      <Formik
        initialValues={{}}
        onSubmit={(values) => setSearchParams(values)}
        onReset={(values) => setSearchParams(values)}
      >
        {({
          values, handleChange, handleBlur, handleSubmit, setFieldValue, resetForm,
        }) => (
          <form onSubmit={handleSubmit} onReset={resetForm}>
            <Row gutter={[8, 8]}>
              <Col flex="1 1 500px">
                <Row justify="start">
                  <Col span={4}>
                    <Input
                      type="text"
                      name="search"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.search}
                      placeholder="Город"
                    />
                  </Col>

                  <Col span={4}>
                    <Select
                      name="disabled"
                      onChange={(value) => setFieldValue('disabled', value)}
                      onBlur={handleBlur}
                      defaultValue={values.disabled}
                      style={{ width: 120 }}
                      placeholder="Включен"
                    >
                      <Option value="">Все</Option>
                      <Option value="false">Включен</Option>
                      <Option value="true">Выключен</Option>
                    </Select>
                  </Col>

                  <Col span={2}>
                    <Button htmlType="submit" type="primary" icon={<SearchOutlined />}>
                      Найти
                    </Button>
                  </Col>

                  <Col span={2}>
                    <Button htmlType="reset" type="primary" icon={<CloseCircleOutlined />}>
                      Очистить
                    </Button>
                  </Col>
                </Row>
              </Col>

              <Col flex="0 1 100px">
                <Button type="primary" onClick={() => history.push('/departure-cities/add')}>
                  Добавить город отправления
                </Button>
              </Col>
            </Row>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default TopPanel;
