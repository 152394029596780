import React from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray } from 'formik';
import { Col, Row, Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import InputField from '../../common/components/formFields/InputField';
import FieldTextarea from '../../common/components/formFields/FieldTextarea';

const Room = ({
  loading, values, errors, touched, handleSubmit,
}) => {
  function renderFieldArray(arrayHelpers, fields) {
    return (
      <>
        <Row gutter={[4, 16]}>
          <Col>
            <span style={{ verticalAlign: 'middle' }}>Matching*</span>
          </Col>

          <Col>
            <Button type="primary" onClick={() => arrayHelpers.insert(0, '')}>Добавить еще</Button>
          </Col>
        </Row>

        {fields.map((field, index) => (
          <Row gutter={[24, 16]} key={index} className="inlineFieldsRowWrap">
            <Col>
              <Row gutter={4}>
                <Col>
                  <Field name={`matching.${index}`} component={InputField} type="text" classes="form-control" />
                </Col>

                <Col>
                  <Button type="danger" icon={<DeleteOutlined />} onClick={() => arrayHelpers.remove(index)} />
                </Col>
              </Row>
            </Col>
          </Row>
        ))}
      </>
    );
  }

  return (
    <Row>
      <Col span={20} offset={2}>
        <form onSubmit={handleSubmit}>
          <Row gutter={[8, 16]}>
            <Col>
              <Field
                type="text"
                name="hotelId"
                addonBefore="ID отеля"
                component={InputField}
                disabled
                className={errors.hotelId && touched.hotelId && 'ant-form-item-has-error'}
              />
            </Col>
          </Row>

          <Row gutter={[8, 16]}>
            <Col>
              <Field
                type="text"
                name="title.ru"
                addonBefore="Название комнаты (рус)"
                component={InputField}
                className={errors.title && errors.title.ru && touched.title.ru && 'ant-form-item-has-error'}
              />
            </Col>

            <Col>
              <Field
                type="text"
                name="title.uk"
                addonBefore="Название комнаты (укр)"
                component={InputField}
                className={errors.title && errors.title.uk && touched.title.uk && 'ant-form-item-has-error'}
              />
            </Col>
          </Row>

          <Row gutter={[8, 16]}>
            <Col>
              <Field
                type="text"
                name="description.ru"
                addonBefore="Описание комнаты (рус)"
                component={FieldTextarea}
                rows={6}
              />
            </Col>

            <Col>
              <Field
                type="text"
                name="description.uk"
                addonBefore="Описание комнаты (укр)"
                component={FieldTextarea}
                rows={6}
              />
            </Col>
          </Row>

          <Row gutter={[8, 16]}>
            <Col>
              <FieldArray
                name="matching"
                render={(arrayHelpers) => renderFieldArray(arrayHelpers, values.matching)}
              />
            </Col>
          </Row>

          <Row gutter={[8, 16]}>
            <Col>
              <Button type="primary" htmlType="submit" disabled={loading}>
                Сохранить
              </Button>
            </Col>
          </Row>
        </form>
      </Col>
    </Row>
  );
};

export default Room;

Room.defaultProps = {
  values: {
    matching: [],
  },
};

Room.propTypes = {
  loading: PropTypes.bool.isRequired,
  values: PropTypes.shape({
    matching: PropTypes.array,
  }),
  errors: PropTypes.shape({
    title: PropTypes.object,
    hotelId: PropTypes.string,
  }).isRequired,
  touched: PropTypes.shape({
    title: PropTypes.object,
    hotelId: PropTypes.bool,
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
};
