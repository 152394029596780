import React, { useContext } from 'react';
import { PageHeader } from 'antd';

import { StarsContext } from './Provider';
import Form from './Form';

const AddView = () => {
  const { loading, addStar } = useContext(StarsContext);

  return (
    <div>
      <PageHeader className="site-page-header" title="Добавить категорию отеля" />
      <Form loading={loading} addStar={addStar} />
    </div>
  );
};

export default AddView;
