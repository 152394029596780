import React, { useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import {
  Select, Button, Col, Row,
} from 'antd';
import * as Yup from 'yup';

import InputField from '../common/components/formFields/InputField';
import { CountriesContext } from '../countries/Provider';

const { Option } = Select;

const Form = ({
  edit, loading, initialValues, addRegion, editRegion,
}) => {
  const history = useHistory();
  const { countries, fetchCountries } = useContext(CountriesContext);
  const formSchema = Yup.object().shape({
    name: Yup.object().shape({
      ru: Yup.string().required(),
      uk: Yup.string().required(),
    }),
    countryId: Yup.string().required(),
  });

  useEffect(() => {
    fetchCountries({ limit: 0 });
  }, []);

  function onSubmit(values) {
    if (edit) {
      return editRegion(values.id, values, () => history.push('/regions'));
    }

    return addRegion(values, () => history.push('/regions'));
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={formSchema}
      validateOnChange={false}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }) => (
        <Row>
          <Col span={20} offset={2}>
            <form onSubmit={handleSubmit}>
              <Row gutter={[8, 16]}>
                <Col>
                  <Field
                    type="text"
                    name="name.ru"
                    addonBefore="Название региона (рус)"
                    component={InputField}
                    className={errors.name && errors.name.ru && touched.name.ru && 'ant-form-item-has-error'}
                  />
                </Col>

                <Col>
                  <Field
                    type="text"
                    name="name.uk"
                    addonBefore="Название региона (укр)"
                    component={InputField}
                    className={errors.name && errors.name.uk && touched.name.uk && 'ant-form-item-has-error'}
                  />
                </Col>
              </Row>

              <Row gutter={[8, 16]}>
                <Col className={errors.countryId && touched.countryId && 'ant-form-item-has-error'}>
                  <Select
                    name="countryId"
                    defaultValue={values.countryId}
                    onChange={(value) => setFieldValue('countryId', value)}
                    placeholder="Страна"
                    style={{ width: 250 }}
                  >
                    {countries.map((country) => (
                      <Option value={country.id} key={country.id}>{country.name.ru}</Option>
                    ))}
                  </Select>
                </Col>
              </Row>

              <Row gutter={[8, 16]}>
                <Col>
                  <Select
                    name="default"
                    onChange={(value) => setFieldValue('default', value)}
                    onBlur={handleBlur}
                    defaultValue={values.default}
                    style={{ width: 170 }}
                  >
                    <Option value>По умолчанию</Option>
                    <Option value={false}>Не по умолчанию</Option>
                  </Select>
                </Col>

                <Col>
                  <Select
                    name="disabled"
                    onChange={(value) => setFieldValue('disabled', value)}
                    onBlur={handleBlur}
                    defaultValue={values.disabled}
                    style={{ width: 120 }}
                  >
                    <Option value={false}>Включен</Option>
                    <Option value>Выключен</Option>
                  </Select>
                </Col>

                <Col>
                  <Select
                    name="popular"
                    onChange={(value) => setFieldValue('popular', value)}
                    onBlur={handleBlur}
                    defaultValue={values.popular}
                    style={{ width: 120 }}
                  >
                    <Option value>Популярный</Option>
                    <Option value={false}>Не популярный</Option>
                  </Select>
                </Col>
              </Row>

              <Button type="primary" htmlType="submit" disabled={edit && loading}>
                {edit ? 'Сохранить' : 'Добавить регион'}
              </Button>
            </form>
          </Col>
        </Row>
      )}
    </Formik>
  );
};

export default Form;

Form.defaultProps = {
  initialValues: {
    name: {
      ru: '',
      uk: '',
    },
    default: false,
    disabled: false,
    popular: false,
    countryId: undefined,
  },
  edit: false,
  loading: false,
  addRegion: () => {},
  editRegion: () => {},
};

Form.propTypes = {
  edit: PropTypes.bool,
  loading: PropTypes.bool,
  initialValues: PropTypes.shape({}),
  addRegion: PropTypes.func,
  editRegion: PropTypes.func,
};
