import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import {
  Button, Col, Row, Select,
} from 'antd';
import { CloseOutlined, CheckOutlined, SyncOutlined } from '@ant-design/icons';

import InputField from '../../common/components/formFields/InputField';
import FieldCheckbox from '../../common/components/formFields/FieldCheckbox';

const { Option } = Select;

const Hotel = ({
  loading, history, values, errors, touched, countries, cities, stars,
  setFieldValue, handleSubmit, fetchCities, syncHotelWithTripAdvisor,
}) => {
  const [tripAdvisorButton, setTripAdvisorButton] = useState({
    icon: <SyncOutlined />,
    disabled: true,
    loading: false,
    url: '',
  });

  const currentCities = cities.filter((city) => city.countryId === values.countryId);

  function setUrl(event) {
    const { value } = event.currentTarget;

    if (value.length) {
      setTripAdvisorButton({ ...tripAdvisorButton, disabled: false, url: value });

      return;
    }

    setTripAdvisorButton({ ...tripAdvisorButton, disabled: true });
  }

  async function getTripAdvisorData() {
    setTripAdvisorButton({ ...tripAdvisorButton, loading: true });

    const data = await syncHotelWithTripAdvisor({ hotelId: values.id, url: tripAdvisorButton.url });

    if (!data) {
      setTripAdvisorButton({ ...tripAdvisorButton, icon: <CloseOutlined />, loading: false });

      return;
    }

    setTripAdvisorButton({ ...tripAdvisorButton, icon: <CheckOutlined />, loading: false });
  }

  return (
    <Row>
      <Col span={20} offset={2}>
        <form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Select
                name="countryId"
                onChange={(value) => {
                  setFieldValue('countryId', value);
                  fetchCities({ countryId: value, limit: 0 });
                }}
                placeholder="Страна"
                defaultValue={values.countryId}
                style={{ width: 200 }}
              >
                {countries.map((country) => (
                  <Option value={country.id} key={country.id}>{country.name.ru}</Option>
                ))}
              </Select>
            </Col>

            <Col span={3}>
              <Select
                name="cityId"
                onChange={(value) => setFieldValue('cityId', value)}
                placeholder="Город"
                style={{ width: 200 }}
                defaultValue={values.cityId}
                disabled={!currentCities.length}
              >
                {currentCities.map((city) => (
                  <Option value={city.id} key={city.id}>{city.name.ru}</Option>
                ))}
              </Select>
            </Col>
          </Row>

          <Row gutter={[8, 16]}>
            <Col>
              <Field
                type="text"
                name="name"
                addonBefore="Название отеля"
                component={InputField}
                className={errors.name && touched.name && 'ant-form-item-has-error'}
              />
            </Col>

            <Col>
              <Select
                name="starId"
                onChange={(value) => setFieldValue('starId', value)}
                defaultValue={values.starId}
                placeholder="Выберите звездность"
                style={{ width: 200 }}
              >
                {stars.map((star) => (
                  <Option value={star.id} key={star.id}>{star.name.ru}</Option>
                ))}
              </Select>
            </Col>
          </Row>

          <Row gutter={[8, 16]}>
            <Col>
              <Field
                name="address"
                component={InputField}
                type="text"
                addonBefore="Адрес"
                placeholder="Адрес"
              />
            </Col>
          </Row>

          <Row gutter={[8, 16]}>
            <Col>
              <Field name="latLng.lat" component={InputField} type="text" placeholder="Широта - 50.40213" />
            </Col>

            <Col>
              <Field name="latLng.lng" component={InputField} type="text" placeholder="Долгота - 30.25251" />
            </Col>
          </Row>

          <Row gutter={[8, 16]}>
            <Col>
              <div>Ccылка на vsezdes.online</div>
              <a href={`https://app.vsezdes.online/tour/${values.alias}`}>
                https://app.vsezdes.online/tour/
                {values.alias}
              </a>
            </Col>
          </Row>

          <Row gutter={[8, 16]}>
            <Col>
              <Field
                name="youtube"
                component={InputField}
                type="text"
                placeholder="Ссылка на видео"
                addonBefore="Youtube ссылка"
              />
            </Col>
          </Row>

          <Row gutter={[8, 16]}>
            <Col>
              <Field
                name="tripAdvisorUrl"
                component={InputField}
                type="text"
                placeholder="Ссылка на tripadvisor"
                addonBefore="Ccылка на tripadvisor.com"
                onChange={(event) => setUrl(event)}
                defaultValue={values.tripAdvisorData && values.tripAdvisorData.url}
              />
            </Col>

            <Col>
              <Button
                disabled={tripAdvisorButton.disabled}
                loading={tripAdvisorButton.loading}
                icon={tripAdvisorButton.icon}
                onClick={() => getTripAdvisorData()}
              >
                Синхронизировать
              </Button>
            </Col>
          </Row>

          <Row gutter={[8, 16]}>
            <Col>
              <Field
                name="bought"
                component={InputField}
                type="number"
                placeholder="Купили раз"
                addonBefore="Купили раз"
              />
            </Col>
          </Row>

          <Row gutter={[8, 16]}>
            <Col>
              <Field
                name="marketing.recommended"
                component={FieldCheckbox}
                type="checkbox"
                addonBefore="Recommended be VseZdes"
              />
            </Col>

            <Col>
              <Field
                name="marketing.topSales"
                component={FieldCheckbox}
                type="checkbox"
                addonBefore="Top Sales"
              />
            </Col>

            <Col>
              <Field
                name="marketing.cheap"
                component={FieldCheckbox}
                type="checkbox"
                addonBefore="Cheap Hotel"
              />
            </Col>

            <Col>
              <Field
                name="marketing.certificated"
                component={FieldCheckbox}
                type="checkbox"
                addonBefore="Certificated Hotel"
              />
            </Col>

            <Col>
              <Field
                name="marketing.blackFriday"
                component={FieldCheckbox}
                type="checkbox"
                addonBefore="Black Friday"
              />
            </Col>
          </Row>

          <Row gutter={[8, 16]}>
            <Col>
              <Select
                name="disabled"
                onChange={(value) => setFieldValue('disabled', value)}
                defaultValue={values.disabled}
                style={{ width: 120 }}
              >
                <Option value={false}>Включен</Option>
                <Option value>Выключен</Option>
              </Select>
            </Col>
          </Row>

          <Row gutter={[8, 16]}>
            <Col>
              <Button type="secondary" onClick={() => history.push('/hotels')}>
                Отменить
              </Button>
            </Col>

            <Col>
              <Button type="primary" htmlType="submit" disabled={loading}>
                Сохранить
              </Button>
            </Col>
          </Row>
        </form>
      </Col>
    </Row>
  );
};

export default Hotel;

Hotel.defaultProps = {
  values: {
    name: '',
    countryId: null,
    regionId: null,
  },
};

Hotel.propTypes = {
  loading: PropTypes.bool.isRequired,
  history: PropTypes.any.isRequired,
  values: PropTypes.shape({
    id: PropTypes.string,
    alias: PropTypes.string,
    disabled: PropTypes.bool,
    starId: PropTypes.string,
    countryId: PropTypes.string,
    cityId: PropTypes.string,
    tripAdvisorData: PropTypes.shape({
      url: PropTypes.string,
    }),
    marketing: PropTypes.shape({
      recommended: PropTypes.bool,
      topSales: PropTypes.bool,
      cheap: PropTypes.bool,
      certificated: PropTypes.bool,
      blackFriday: PropTypes.bool,
    }),
  }),
  errors: PropTypes.shape({
    name: PropTypes.object,
  }).isRequired,
  touched: PropTypes.shape({
    name: PropTypes.bool,
  }).isRequired,
  countries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  cities: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  stars: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  fetchCities: PropTypes.func.isRequired,
  syncHotelWithTripAdvisor: PropTypes.func.isRequired,
};
