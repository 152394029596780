import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Spin, PageHeader } from 'antd';

import { StarsContext } from './Provider';
import Form from './Form';

const EditView = () => {
  const { id } = useParams();
  const {
    currentStar, loading, getStar, editStar,
  } = useContext(StarsContext);

  useEffect(() => {
    getStar(id);
  }, []);

  if (loading || !currentStar) {
    return <Spin tip="Loading..." size="large" />;
  }

  return (
    <div>
      <PageHeader className="site-page-header" title="Редактировать категорию отеля" />
      <Form edit loading={loading} initialValues={currentStar} editStar={editStar} />
    </div>
  );
};

export default EditView;
