import React, { useContext } from 'react';
import { Formik } from 'formik';
import { Tabs } from 'antd';
import * as Yup from 'yup';

import { ReviewsContext } from './Provider';
import Review from './forms/Review';
import Images from './forms/Images';
import formatImages from '../utils/formatImages';

const { TabPane } = Tabs;

const Form = () => {
  const { loading, currentReview, editReview } = useContext(ReviewsContext);
  const formSchema = Yup.object().shape({
    content: Yup.object().shape({
      author: Yup.string().required(),
      title: Yup.string().required(),
      text: Yup.string().required(),
    }),
    hotelId: Yup.string().required(),
    type: Yup.string().required(),
  });

  function onSubmit(values) {
    const formattedImages = formatImages(values.content.images, 1);
    const newValues = {
      ...values,
      content: {
        ...values.content,
        images: formattedImages,
      },
    };

    return editReview(values.id, newValues, () => window.location.reload());
  }

  return (
    <Formik
      enableReinitialize
      initialValues={currentReview}
      onSubmit={onSubmit}
      validationSchema={formSchema}
      validateOnChange={false}
    >
      {({
        values,
        errors,
        touched,
        handleSubmit,
        setFieldValue,
      }) => (
        <Tabs defaultActiveKey="1">
          <TabPane tab="Общая информация" key="1">
            <Review
              loading={loading}
              errors={errors}
              touched={touched}
              hotelId={currentReview.hotelId}
              handleSubmit={handleSubmit}
            />
          </TabPane>

          <TabPane tab="Изображения" key="2">
            <Images
              loading={loading}
              values={values}
              setFieldValue={setFieldValue}
              handleSubmit={handleSubmit}
            />
          </TabPane>
        </Tabs>
      )}
    </Formik>
  );
};

export default Form;
