import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Spin, PageHeader } from 'antd';

import { CitiesContext } from './Provider';
import Form from './Form';

const EditView = () => {
  const { id } = useParams();
  const {
    loading, currentCity, getCity, editCity,
  } = useContext(CitiesContext);

  useEffect(() => {
    getCity(id);
  }, []);

  if (loading || !currentCity) {
    return <Spin tip="Loading..." size="large" />;
  }

  return (
    <div>
      <PageHeader className="site-page-header" title="Редактировать город" />
      <Form
        edit
        loading={loading}
        initialValues={currentCity}
        editCity={editCity}
      />
    </div>
  );
};

export default EditView;
