import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Spin, PageHeader } from 'antd';

import { RegionsContext } from './Provider';
import Form from './Form';

const EditView = () => {
  const { id } = useParams();
  const {
    loading, currentRegion, getRegion, editRegion,
  } = useContext(RegionsContext);

  useEffect(() => {
    getRegion(id);
  }, []);

  if (loading || !currentRegion) {
    return <Spin tip="Loading..." size="large" />;
  }

  return (
    <div>
      <PageHeader className="site-page-header" title="Редактировать регион" />
      <Form
        edit
        loading={loading}
        initialValues={currentRegion}
        editRegion={editRegion}
      />
    </div>
  );
};

export default EditView;
