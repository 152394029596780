import axios from 'axios';
import { getStorageValue, setStorageValue, removeFromStorage } from './storage';
import { API_URL } from '../constants';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const BRANCH_ID = process.env.REACT_APP_BRANCH_ID;

const baseConfig = {
  baseURL: BACKEND_URL,
  timeout: 60000,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'X-Branch': BRANCH_ID,
  },
};

const callApi = axios.create(baseConfig);

callApi.interceptors.request.use((config) => {
  const token = getStorageValue('token');
  config.headers.Authorization = token; // eslint-disable-line

  return config;
});

callApi.interceptors.response.use(null, async (error) => {
  if (error.response.data.code === 401) {
    const refreshToken = getStorageValue('refreshToken');

    try {
      const authData = await callApi.post(`${API_URL}/auth/sso/refresh`, { refreshToken });

      const tokens = authData.data.result.tokens || {};

      setStorageValue('token', tokens.token, true);
      setStorageValue('refreshToken', tokens.refreshToken, true);

      error.config.headers.Authorization = tokens.token; // eslint-disable-line

      return axios.request(error.config);
    } catch (e) {
      removeFromStorage('token');
      removeFromStorage('refreshToken');

      window.location.href = '/login';
    }
  }

  return Promise.reject(error);
});


export default callApi;
