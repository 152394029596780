import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NotificationManager } from 'react-notifications';

import {
  listTourOperatorsApi, getTourOperatorApi, addTourOperatorApi, editTourOperatorApi, deleteTourOperatorApi,
} from './api';
import getSearchParams from '../utils/getSearchParams';
import getErrorMessage from '../utils/getErrorMessage';

const TourOperatorsContext = React.createContext();

const TourOperatorsProvider = ({ children }) => {
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useState({});
  const [tourOperators, setTourOperators] = useState([]);
  const [currentTourOperator, setCurrentTourOperator] = useState(null);

  const fetchTourOperators = async (params = null) => {
    const finalParams = params || getSearchParams(searchParams);

    try {
      const tourOperatorsData = await listTourOperatorsApi(finalParams);

      setTourOperators(tourOperatorsData.data.items);
      setCount(tourOperatorsData.data.count);
      setLoading(false);
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  const getTourOperator = async (id) => {
    try {
      setCurrentTourOperator(null);

      const tourOperator = await getTourOperatorApi(id);

      setLoading(false);
      setCurrentTourOperator(tourOperator.data);
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  const addTourOperator = async (data, callback) => {
    try {
      await addTourOperatorApi(data);

      setLoading(false);

      if (callback && typeof callback === 'function') {
        callback();
      }
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  const editTourOperator = async (id, data, callback) => {
    try {
      const tourOperator = await editTourOperatorApi(id, data);
      const index = tourOperators.findIndex((item) => item.id === tourOperator.data.id);
      const newTourOperators = [...tourOperators];

      newTourOperators.splice(index, 1, tourOperator.data);

      setTourOperators(newTourOperators);
      setLoading(false);

      if (callback && typeof callback === 'function') {
        callback();
      }
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  const deleteTourOperator = async (id) => {
    try {
      await deleteTourOperatorApi(id);
      await fetchTourOperators();
      setLoading(false);
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  return (
    <TourOperatorsContext.Provider value={{
      count,
      loading,
      searchParams,
      tourOperators,
      currentTourOperator,
      setSearchParams,
      fetchTourOperators,
      getTourOperator,
      addTourOperator,
      editTourOperator,
      deleteTourOperator,
    }}
    >
      {children}
    </TourOperatorsContext.Provider>
  );
};

TourOperatorsProvider.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};

export { TourOperatorsProvider, TourOperatorsContext };
