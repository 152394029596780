import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import {
  Button, Col, Row,
} from 'antd';

import InputField from '../common/components/formFields/InputField';

const Form = ({
  edit, loading, initialValues, addTourOperator, editTourOperator,
}) => {
  const history = useHistory();

  function onSubmit(values) {
    if (edit) {
      return editTourOperator(values.id, values, () => history.push('/tour-operators'));
    }

    return addTourOperator(values, () => history.push('/tour-operators'));
  }

  return (
    <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit}>
      {({
        handleSubmit,
      }) => (
        <Row>
          <Col span={20} offset={2}>
            <form onSubmit={handleSubmit}>
              <Row gutter={[8, 16]}>
                <Col>
                  <Field name="name" component={InputField} type="text" addonBefore="Название тур оператора" />

                  <Field name="andromedaId" component={InputField} type="number" addonBefore="Andromeda ID" />
                </Col>
              </Row>

              <Button type="primary" htmlType="submit" disabled={loading}>
                {edit ? 'Сохранить' : 'Добавить тур оператора'}
              </Button>
            </form>
          </Col>
        </Row>
      )}
    </Formik>
  );
};

export default Form;

Form.defaultProps = {
  initialValues: {
    name: '',
    icon: '',
    andromedaId: 0,
  },
  edit: false,
  loading: false,
  addTourOperator: () => {},
  editTourOperator: () => {},
};

Form.propTypes = {
  edit: PropTypes.bool,
  loading: PropTypes.bool,
  initialValues: PropTypes.shape({}),
  addTourOperator: PropTypes.func,
  editTourOperator: PropTypes.func,
};
