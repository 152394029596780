import React from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip } from 'antd';

class ActionButton extends React.PureComponent {
  render() {
    const {
      disabled, onClick, title, tooltip, type, icon, placement,
    } = this.props;

    return (
      <Tooltip
        title={tooltip}
        placement={placement}
      >
        <Button
          type={type}
          icon={icon}
          disabled={disabled}
          onClick={onClick}
          className={disabled && 'disabled'}
        >
          {title}
        </Button>
      </Tooltip>
    );
  }
}

ActionButton.propTypes = {
  disabled: PropTypes.bool,
  type: PropTypes.string,
  icon: PropTypes.element,
  title: PropTypes.string,
  onClick: PropTypes.func,
  tooltip: PropTypes.string,
  placement: PropTypes.string,
};

ActionButton.defaultProps = {
  type: '',
  icon: <div />,
  title: '',
  tooltip: '',
  disabled: false,
  onClick: () => {},
  placement: 'topRight',
};

export default ActionButton;
