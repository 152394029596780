class ParentsSearchParams {
  constructor(items, parentsKeys) {
    this.items = items;
    this.parentsKeys = parentsKeys;
    this.parentsData = {};
    this.parentsSearchParams = {};
  }

  static getParentMap(parentItems) {
    const map = new Map();

    parentItems.forEach((item) => map.set(item.id, item.name.ru));

    return map;
  }

  getParentsSearchParams() {
    this.createPropsForParents();
    this.getParentsIds();
    this.makeParentsIdsUnique();


    Object.keys(this.parentsData).forEach((key) => {
      this.parentsData[key].forEach((id) => {
        if (id) {
          this.parentsSearchParams[key].append('id', id);
        }
      });
    });

    return this.parentsSearchParams;
  }

  createPropsForParents() {
    this.parentsKeys.forEach((key) => {
      this.parentsData[key] = [];
      this.parentsSearchParams[key] = new URLSearchParams();
    });
  }

  getParentsIds() {
    this.items.forEach((item) => {
      this.parentsKeys.forEach((key) => {
        if (key in item) {
          this.parentsData[key].push(item[key]);
        }
      });
    });
  }

  makeParentsIdsUnique() {
    Object.keys(this.parentsData).forEach((key) => {
      this.parentsData[key] = [...new Set(this.parentsData[key])];
    });
  }
}

export default ParentsSearchParams;
