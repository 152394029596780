import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NotificationManager } from 'react-notifications';

import {
  listStarsApi, getStarApi, addStarApi, editStarApi, deleteStarApi,
} from './api';
import getSearchParams from '../utils/getSearchParams';
import getErrorMessage from '../utils/getErrorMessage';

const StarsContext = React.createContext();

const StarsProvider = ({ children }) => {
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useState({});
  const [stars, setStars] = useState([]);
  const [currentStar, setCurrentStar] = useState(null);

  const clearFetchStars = (params) => listStarsApi(params);

  const fetchStars = async (params = null) => {
    const finalParams = params || getSearchParams(searchParams);

    try {
      const starsData = await listStarsApi(finalParams);

      setStars(starsData.data.items);
      setCount(starsData.data.count);
      setLoading(false);
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  const getStar = async (id) => {
    try {
      setCurrentStar(null);

      const star = await getStarApi(id);

      setLoading(false);
      setCurrentStar(star.data);
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  const addStar = async (data, callback) => {
    try {
      await addStarApi(data);
      setLoading(false);

      if (callback && typeof callback === 'function') {
        callback();
      }
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  const editStar = async (id, data, callback) => {
    try {
      const star = await editStarApi(id, data);
      const index = stars.findIndex((item) => item.id === star.data.id);
      const newStars = [...stars];

      newStars.splice(index, 1, star.data);

      setStars(newStars);
      setLoading(false);

      if (callback && typeof callback === 'function') {
        callback();
      }
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  const deleteStar = async (id) => {
    try {
      await deleteStarApi(id);
      await fetchStars();
      setLoading(false);
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  return (
    <StarsContext.Provider value={{
      count,
      loading,
      searchParams,
      stars,
      currentStar,
      setSearchParams,
      fetchStars,
      clearFetchStars,
      getStar,
      addStar,
      editStar,
      deleteStar,
    }}
    >
      {children}
    </StarsContext.Provider>
  );
};

StarsProvider.propTypes = {
  children: PropTypes.object.isRequired,
};

export { StarsProvider, StarsContext };
