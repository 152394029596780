import React from 'react';
import PropTypes from 'prop-types';
import { Table, Modal } from 'antd';
import {
  DeleteOutlined, SyncOutlined,
} from '@ant-design/icons';

import NoData from '../../common/components/NoData';
import ActionButton from '../../common/components/ActionButton';

const LinksTable = ({
  items, onDelete, synchronizeOtpuskHotel, otpuskConfirmModal, setOtpuskConfirmModal,
}) => {
  function renderDeleteButton(...args) {
    const [, , index] = args;

    return (
      <ActionButton
        type="primary"
        icon={<DeleteOutlined />}
        tooltip="Удалить"
        className="deleteAction"
        onClick={() => onDelete(index)}
      />
    );
  }

  function renderSynchronizeButton(item) {
    if (item.operator !== 'otpuskId') {
      return null;
    }

    return (
      <ActionButton
        type="primary"
        icon={<SyncOutlined />}
        tooltip="Синхронизировать"
        disabled={otpuskConfirmModal.disabledSyncButton}
        onClick={() => setOtpuskConfirmModal({ ...otpuskConfirmModal, visible: true, otpuskId: item.id })}
      />
    );
  }

  function handleModalOk() {
    setOtpuskConfirmModal({
      ...otpuskConfirmModal,
      confirmLoading: true,
    });

    synchronizeOtpuskHotel(Number(otpuskConfirmModal.otpuskId));
  }

  function handleModalCancel() {
    if (otpuskConfirmModal.confirmLoading) {
      return;
    }

    setOtpuskConfirmModal({
      ...otpuskConfirmModal,
      visible: false,
    });
  }

  const columns = [
    { dataIndex: 'operator', title: 'Оператор' },
    { dataIndex: 'id', title: 'ID' },
    {
      width: 50, title: () => <SyncOutlined />, dataIndex: '', key: 'Synchronize', render: renderSynchronizeButton,
    },
    {
      width: 50, title: () => <DeleteOutlined />, dataIndex: '', key: 'Delete', render: renderDeleteButton,
    },
  ];

  const tableHorizontalScroll = columns.reduce((sum, col) => (sum + col.width || 0), 0);

  if (!items.length) return <NoData />;

  return (
    <>
      <Table
        columns={columns}
        rowKey={(record) => record.id + record.operator}
        dataSource={items}
        pagination={false}
        scroll={{ x: tableHorizontalScroll }}
      />

      <Modal
        visible={otpuskConfirmModal.visible}
        confirmLoading={otpuskConfirmModal.confirmLoading}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
      >
        <p>Вы уверены что хотите синхронизировать отлеь с этим OtpuskId?</p>
      </Modal>
    </>
  );
};

LinksTable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})),
  synchronizeOtpuskHotel: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  otpuskConfirmModal: PropTypes.shape({
    disabledSyncButton: PropTypes.bool,
    otpuskId: PropTypes.number,
    confirmLoading: PropTypes.bool,
    visible: PropTypes.bool,
  }).isRequired,
  setOtpuskConfirmModal: PropTypes.func.isRequired,
};

LinksTable.defaultProps = {
  items: [],
};

export default LinksTable;
