import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NotificationManager } from 'react-notifications';

import {
  listDepartureCitiesApi, getDepartureCityApi, addDepartureCityApi, editDepartureCityApi, deleteDepartureCityApi,
} from './api';
import getSearchParams from '../utils/getSearchParams';
import getErrorMessage from '../utils/getErrorMessage';

const DepartureCitiesContext = React.createContext();

const DepartureCitiesProvider = ({ children }) => {
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useState({});
  const [departureCities, setDepartureCities] = useState([]);
  const [currentDepartureCity, setCurrentDepartureCity] = useState(null);

  const fetchDepartureCities = async (params = null) => {
    const finalParams = params || getSearchParams(searchParams);

    try {
      const departureCitiesData = await listDepartureCitiesApi(finalParams);

      setDepartureCities(departureCitiesData.data.items);
      setCount(departureCitiesData.data.count);
      setLoading(false);
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  const getDepartureCity = async (id) => {
    try {
      setCurrentDepartureCity(null);

      const departureCity = await getDepartureCityApi(id);

      setLoading(false);
      setCurrentDepartureCity(departureCity.data);
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  const addDepartureCity = async (data, callback) => {
    try {
      await addDepartureCityApi(data);
      setLoading(false);

      if (callback && typeof callback === 'function') {
        callback();
      }
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  const editDepartureCity = async (id, data, callback) => {
    try {
      await editDepartureCityApi(id, data);
      await fetchDepartureCities();

      if (callback && typeof callback === 'function') {
        callback();
      }
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  const deleteDepartureCity = async (id) => {
    try {
      await deleteDepartureCityApi(id);
      await fetchDepartureCities();
      setLoading(false);
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  return (
    <DepartureCitiesContext.Provider value={{
      count,
      loading,
      searchParams,
      departureCities,
      currentDepartureCity,
      setSearchParams,
      fetchDepartureCities,
      getDepartureCity,
      addDepartureCity,
      editDepartureCity,
      deleteDepartureCity,
    }}
    >
      {children}
    </DepartureCitiesContext.Provider>
  );
};

DepartureCitiesProvider.propTypes = {
  children: PropTypes.object.isRequired,
};

export { DepartureCitiesProvider, DepartureCitiesContext };
