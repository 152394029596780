import React, { useContext, useEffect } from 'react';
import { Spin, PageHeader, Pagination } from 'antd';

import { TourOperatorsContext } from './Provider';
import TourOperatorsTable from './Table';
import TourOperatorsTopPanel from './TopPanel';

const TourOperators = () => {
  const {
    loading, count, searchParams, setSearchParams, fetchTourOperators,
  } = useContext(TourOperatorsContext);

  function onChangePagination(page, pageSize) {
    const skip = (page - 1) * pageSize;

    setSearchParams({ ...searchParams, skip });
  }

  useEffect(() => {
    fetchTourOperators();
  }, [searchParams]);

  if (loading) {
    return <Spin tip="Loading..." size="large" />;
  }

  return (
    <div style={{ margin: 30 }}>
      <PageHeader className="site-page-header" title="Тур операторы" />
      <TourOperatorsTopPanel fetchTourOperators={fetchTourOperators} />
      <TourOperatorsTable />
      <Pagination
        defaultCurrent={1}
        defaultPageSize={20}
        total={count}
        onChange={onChangePagination}
      />
    </div>
  );
};

export default TourOperators;
