import React from 'react';
import PropTypes from 'prop-types';
import { Table, Radio } from 'antd';

import NoData from '../../common/components/NoData';

const ServicesTable = ({
  items, onChange,
}) => {
  function renderActions(...args) {
    const [, rowItem] = args;

    return (
      <Radio.Group onChange={(event) => onChange(rowItem.key, event.target.value)} defaultValue={rowItem.value}>
        {rowItem.possibleValues.map((element) => (
          <Radio value={element} key={element}>{element}</Radio>
        ))}
      </Radio.Group>
    );
  }

  const columns = [
    { dataIndex: 'name', title: 'Название' },
    {
      title: 'Атрибуты', dataIndex: '', key: 'actions', render: renderActions,
    },
  ];

  const tableHorizontalScroll = columns.reduce((sum, col) => (sum + col.width || 0), 0);

  if (!items.length) return <NoData />;

  return (
    <Table
      columns={columns}
      rowKey="name"
      dataSource={items}
      pagination={false}
      scroll={{ x: tableHorizontalScroll }}
    />
  );
};

ServicesTable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})),
  onChange: PropTypes.func.isRequired,
};

ServicesTable.defaultProps = {
  items: [],
};

export default ServicesTable;
