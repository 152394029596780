import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Table, Popconfirm } from 'antd';
import {
  EditOutlined, DeleteOutlined, MinusOutlined, CheckOutlined, PoweroffOutlined,
} from '@ant-design/icons';

import NoData from '../common/components/NoData';
import ActionButton from '../common/components/ActionButton';
import { ReviewsContext } from './Provider';

const ReviewsTable = () => {
  const history = useHistory();
  const { reviews, editReview, deleteReview } = useContext(ReviewsContext);

  function renderEditButton(...args) {
    const [, rowItem] = args;

    return (
      <ActionButton
        type="primary"
        icon={<EditOutlined />}
        tooltip="Править"
        className="editAction"
        onClick={() => history.push(`/reviews/${rowItem.id}/edit`)}
      />
    );
  }

  function renderDeleteButton(...args) {
    const [, rowItem] = args;
    const { id } = rowItem;
    const tooltip = 'Удалить';
    const deleteConfirm = 'Вы уверены, что хотите удалить этот отзыв?';

    return (
      <Popconfirm
        okText="Да"
        cancelText="Нет"
        placement="leftTop"
        title={deleteConfirm}
        onConfirm={() => deleteReview(id)}
      >
        <ActionButton
          type="danger"
          icon={<DeleteOutlined />}
          tooltip={tooltip}
          className="deleteAction"
        />
      </Popconfirm>
    );
  }

  function renderActiveToggleButton(...args) {
    const [, rowItem] = args;
    const { active } = rowItem;

    const icon = active ? <CheckOutlined /> : <MinusOutlined />;
    const tooltip = active ? 'Выключить' : 'Включить';

    return (
      <ActionButton
        icon={icon}
        tooltip={tooltip}
        onClick={() => editReview(rowItem.id, { ...rowItem, active: !active })}
      />
    );
  }

  function renderApprovedToggleButton(...args) {
    const [, rowItem] = args;
    const { approved } = rowItem;

    const icon = approved ? <CheckOutlined /> : <MinusOutlined />;
    const tooltip = approved ? 'Отклонить' : 'Подтвердить';

    return (
      <ActionButton
        icon={icon}
        tooltip={tooltip}
        onClick={() => editReview(rowItem.id, { ...rowItem, approved: !approved })}
      />
    );
  }

  const columns = [
    { dataIndex: 'content', title: 'Название', render: (content) => content.title },
    { dataIndex: 'id', title: 'ID' },
    { dataIndex: 'createdAt', title: 'Дата создания', render: (date) => date.slice(0, 10) },
    {
      width: 50, title: () => <CheckOutlined />, dataIndex: '', key: 'toggle', render: renderApprovedToggleButton,
    },
    {
      width: 50, title: () => <PoweroffOutlined />, dataIndex: '', key: 'toggle', render: renderActiveToggleButton,
    },
    {
      width: 50, title: () => <EditOutlined />, dataIndex: '', key: 'EDIT', render: renderEditButton,
    },
    {
      width: 50, title: () => <DeleteOutlined />, dataIndex: '', key: 'DELETE', render: renderDeleteButton,
    },
  ];

  const tableHorizontalScroll = columns.reduce((sum, col) => (sum + col.width || 0), 0);

  if (!reviews.length) return <NoData />;

  return (
    <Table
      columns={columns}
      rowKey="id"
      dataSource={reviews}
      pagination={false}
      scroll={{ x: tableHorizontalScroll }}
    />
  );
};

export default ReviewsTable;
