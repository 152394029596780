import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Col,
  Modal,
  Row,
  Select,
} from 'antd';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';

import InputField from '../../common/components/formFields/InputField';

const { Option } = Select;

const SingleModal = ({
  countries, cities, addHotel, fetchCities, singleModalVisible, setSingleModalVisible,
}) => {
  const history = useHistory();
  const formSchema = Yup.object().shape({
    name: Yup.string().required(),
    countryId: Yup.string().required(),
    cityId: Yup.string().required(),
  });

  async function onSubmit(values) {
    const hotel = await addHotel(values);

    if (!hotel) {
      return null;
    }

    return history.push(`/hotels/${hotel.id}/edit`);
  }

  return (
    <Modal
      title="Добавить отель"
      visible={singleModalVisible}
      onCancel={() => setSingleModalVisible(false)}
      footer={null}
    >
      <Formik
        initialValues={{ name: '', countryId: null, cityId: null }}
        onSubmit={onSubmit}
        validationSchema={formSchema}
        validateOnChange={false}
      >
        {({
          handleSubmit,
          setFieldValue,
          errors,
          touched,
        }) => (
          <form onSubmit={handleSubmit}>
            <Row gutter={[8, 16]}>
              <Field
                type="text"
                name="name"
                addonBefore="Название отеля"
                component={InputField}
                className={errors.name && touched.name && 'ant-form-item-has-error'}
              />
            </Row>

            <Row gutter={[8, 16]}>
              <Col className={errors.countryId && touched.countryId && 'ant-form-item-has-error'}>
                <Select
                  name="countryId"
                  onChange={(value) => {
                    setFieldValue('countryId', value);
                    fetchCities({ countryId: value });
                  }}
                  placeholder="Страна"
                  style={{ width: 200 }}
                >
                  {countries.map((country) => (
                    <Option value={country.id} key={country.id}>{country.name.ru}</Option>
                  ))}
                </Select>
              </Col>

              <Col className={errors.cityId && touched.cityId && 'ant-form-item-has-error'}>
                <Select
                  name="cityId"
                  onChange={(value) => setFieldValue('cityId', value)}
                  placeholder="Город"
                  style={{ width: 200 }}
                >
                  {cities.map((city) => (
                    <Option value={city.id} key={city.id}>{city.name.ru}</Option>
                  ))}
                </Select>
              </Col>
            </Row>

            <Row gutter={[8, 16]}>
              <Button type="primary" htmlType="submit">
                Добавить
              </Button>
            </Row>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

SingleModal.propTypes = {
  countries: PropTypes.array.isRequired,
  cities: PropTypes.array.isRequired,
  addHotel: PropTypes.func.isRequired,
  fetchCities: PropTypes.func.isRequired,
  singleModalVisible: PropTypes.bool.isRequired,
  setSingleModalVisible: PropTypes.func.isRequired,
};

export default SingleModal;
