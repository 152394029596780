import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { NotificationManager } from 'react-notifications';

import {
  listRegionsApi, getRegionApi, addRegionApi, editRegionApi, deleteRegionApi,
} from './api';
import getSearchParams from '../utils/getSearchParams';
import { CountriesContext } from '../countries/Provider';
import ParentsSearchParams from '../utils/ParentsSearchParams';
import getErrorMessage from '../utils/getErrorMessage';

const RegionsContext = React.createContext();

const RegionsProvider = ({ children }) => {
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useState({});
  const [regions, setRegions] = useState([]);
  const [currentRegion, setCurrentRegion] = useState(null);

  const { clearFetchCountries } = useContext(CountriesContext);

  const clearFetchRegions = (params) => listRegionsApi(params);

  const fetchRegions = async (params = null) => {
    const finalParams = params || getSearchParams(searchParams);

    try {
      const regionsData = await listRegionsApi(finalParams);

      setRegions(regionsData.data.items);
      setCount(regionsData.data.count);
      setLoading(false);
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  const fetchRegionsWithParentsNames = async (params = null) => {
    const finalParams = params || getSearchParams(searchParams);

    try {
      const regionsData = await listRegionsApi(finalParams);
      const parentsSearchParams = new ParentsSearchParams(regionsData.data.items, ['countryId'])
        .getParentsSearchParams();
      const countriesData = await clearFetchCountries(parentsSearchParams.countryId);
      const countriesMap = ParentsSearchParams.getParentMap(countriesData.data.items);

      const regionsWithParentsNames = regionsData.data.items.map(
        (region) => ({ ...region, countryName: countriesMap.get(region.countryId) }),
      );

      setRegions(regionsWithParentsNames);
      setCount(regionsData.data.count);
      setLoading(false);
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  const getRegion = async (id) => {
    try {
      setCurrentRegion(null);

      const region = await getRegionApi(id);

      setLoading(false);
      setCurrentRegion(region.data);
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  const addRegion = async (data, callback) => {
    try {
      await addRegionApi(data);
      setLoading(false);

      if (callback && typeof callback === 'function') {
        callback();
      }
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  const editRegion = async (id, data, callback) => {
    try {
      await editRegionApi(id, data);
      await fetchRegionsWithParentsNames();

      if (callback && typeof callback === 'function') {
        callback();
      }
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  const deleteRegion = async (id) => {
    try {
      await deleteRegionApi(id);
      await fetchRegions();
      setLoading(false);
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  return (
    <RegionsContext.Provider value={{
      count,
      loading,
      searchParams,
      regions,
      currentRegion,
      setSearchParams,
      fetchRegions,
      clearFetchRegions,
      fetchRegionsWithParentsNames,
      getRegion,
      addRegion,
      editRegion,
      deleteRegion,
    }}
    >
      {children}
    </RegionsContext.Provider>
  );
};

RegionsProvider.propTypes = {
  children: PropTypes.object.isRequired,
};

export { RegionsProvider, RegionsContext };
