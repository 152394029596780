import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NotificationManager } from 'react-notifications';

import {
  listReviewsApi, getReviewApi, addReviewApi, editReviewApi, deleteReviewApi,
} from './api';
import getSearchParams from '../utils/getSearchParams';
import getErrorMessage from '../utils/getErrorMessage';

const ReviewsContext = React.createContext();

const ReviewsProvider = ({ children }) => {
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useState({});
  const [reviews, setReviews] = useState([]);
  const [currentReview, setCurrentReview] = useState(null);

  const fetchReviews = async (params = null) => {
    const finalParams = params || getSearchParams(searchParams);

    try {
      const reviewsData = await listReviewsApi(finalParams);

      setReviews(reviewsData.data.items);
      setCount(reviewsData.data.count);
      setLoading(false);
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  const getReview = async (id) => {
    try {
      setCurrentReview(null);

      const review = await getReviewApi(id);

      setLoading(false);
      setCurrentReview(review.data);
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  const addReview = async (data, callback) => {
    try {
      const review = await addReviewApi(data);

      setLoading(false);

      if (callback && typeof callback === 'function') {
        callback();
      }

      return review.data;
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));

      return false;
    }
  };

  const editReview = async (id, data, callback) => {
    try {
      await editReviewApi(id, data);
      await fetchReviews();

      if (callback && typeof callback === 'function') {
        callback();
      }
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  const deleteReview = async (id, hotelId = null) => {
    try {
      await deleteReviewApi(id);
      await fetchReviews({ hotelId });
      setLoading(false);
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  return (
    <ReviewsContext.Provider value={{
      count,
      loading,
      searchParams,
      reviews,
      currentReview,
      setSearchParams,
      fetchReviews,
      getReview,
      addReview,
      editReview,
      deleteReview,
    }}
    >
      {children}
    </ReviewsContext.Provider>
  );
};

ReviewsProvider.propTypes = {
  children: PropTypes.object.isRequired,
};

export { ReviewsProvider, ReviewsContext };
