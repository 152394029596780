import React from 'react';
import { InboxOutlined } from '@ant-design/icons';

const NoData = () => (
  <div>
    <InboxOutlined />
    Нет данных
  </div>
);

export default NoData;
