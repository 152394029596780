import React, { useContext } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Table, Popconfirm } from 'antd';
import {
  EditOutlined, DeleteOutlined, CheckOutlined, MinusOutlined, PoweroffOutlined, FireOutlined,
} from '@ant-design/icons';

import NoData from '../common/components/NoData';
import ActionButton from '../common/components/ActionButton';
import { CitiesContext } from './Provider';

const CitiesTable = () => {
  const history = useHistory();
  const { cities, editCity, deleteCity } = useContext(CitiesContext);

  function renderPopularToggleButton(...args) {
    const [, rowItem] = args;
    const { popular } = rowItem;
    const icon = popular ? <CheckOutlined /> : <MinusOutlined />;
    const tooltip = popular ? 'Сделать не популярным' : 'Сделать популярным';

    return (
      <ActionButton
        icon={icon}
        tooltip={tooltip}
        onClick={() => editCity(rowItem.id, { ...rowItem, popular: !popular })}
      />
    );
  }

  function renderDisabledToggleButton(...args) {
    const [, rowItem] = args;
    const { disabled } = rowItem;

    const icon = disabled ? <MinusOutlined /> : <CheckOutlined />;
    const tooltip = disabled ? 'Включить' : 'Выключить';

    return (
      <ActionButton
        icon={icon}
        tooltip={tooltip}
        onClick={() => editCity(rowItem.id, { ...rowItem, disabled: !disabled })}
      />
    );
  }

  function renderEditButton(...args) {
    const [, rowItem] = args;

    return (
      <ActionButton
        type="primary"
        icon={<EditOutlined />}
        tooltip="Править"
        className="editAction"
        onClick={() => history.push(`/cities/${rowItem.id}/edit`)}
      />
    );
  }

  function renderDeleteButton(...args) {
    const [, rowItem] = args;
    const { id } = rowItem;
    const tooltip = 'Удалить';
    const deleteConfirm = 'Вы уверены, что хотите удалить эту страну?';

    return (
      <Popconfirm
        okText="Да"
        cancelText="Нет"
        placement="leftTop"
        title={deleteConfirm}
        onConfirm={() => deleteCity(id)}
      >
        <ActionButton
          type="danger"
          icon={<DeleteOutlined />}
          tooltip={tooltip}
          className="deleteAction"
        />
      </Popconfirm>
    );
  }

  function renderTitleCell(title, item) {
    return <Link to={{ pathname: '/hotels', state: { cityId: item.id } }}>{title.ru}</Link>;
  }

  const columns = [
    { dataIndex: 'name', title: 'Название', render: renderTitleCell },
    { dataIndex: 'id', title: 'ID' },
    { dataIndex: 'countryName', title: 'Страна' },
    { dataIndex: 'regionName', title: 'Регион' },
    {
      width: 50, title: () => <FireOutlined />, dataIndex: '', key: 'popular', render: renderPopularToggleButton,
    },
    {
      width: 50, title: () => <PoweroffOutlined />, dataIndex: '', key: 'toggle', render: renderDisabledToggleButton,
    },
    {
      width: 50, title: () => <EditOutlined />, dataIndex: '', key: 'EDIT', render: renderEditButton,
    },
    {
      width: 50, title: () => <DeleteOutlined />, dataIndex: '', key: 'DELETE', render: renderDeleteButton,
    },
  ];

  const tableHorizontalScroll = columns.reduce((sum, col) => (sum + col.width || 0), 0);

  if (!cities.length) return <NoData />;

  return (
    <Table
      columns={columns}
      rowKey="id"
      dataSource={cities}
      pagination={false}
      scroll={{ x: tableHorizontalScroll }}
    />
  );
};

export default CitiesTable;
