import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Table } from 'antd';
import {
  EditOutlined, CheckOutlined, MinusOutlined, PoweroffOutlined,
} from '@ant-design/icons';

import NoData from '../common/components/NoData';
import ActionButton from '../common/components/ActionButton';
import { HotelsContext } from './Provider';

const HotelsTable = () => {
  const history = useHistory();
  const { hotels, editHotel } = useContext(HotelsContext);

  function renderDisabledToggleButton(...args) {
    const [, rowItem] = args;
    const { disabled } = rowItem;

    const icon = disabled ? <MinusOutlined /> : <CheckOutlined />;
    const tooltip = disabled ? 'Включить' : 'Выключить';

    return (
      <ActionButton
        icon={icon}
        tooltip={tooltip}
        onClick={() => editHotel(rowItem.id, { ...rowItem, disabled: !disabled })}
      />
    );
  }

  function renderEditButton(...args) {
    const [, rowItem] = args;

    return (
      <ActionButton
        type="primary"
        icon={<EditOutlined />}
        tooltip="Править"
        className="editAction"
        onClick={() => history.push(`/hotels/${rowItem.id}/edit`)}
      />
    );
  }

  const columns = [
    { dataIndex: 'name', title: 'Название' },
    { dataIndex: 'countryName', title: 'Страна', render: (title) => title },
    { dataIndex: 'cityName', title: 'Город', render: (title) => title },
    { dataIndex: 'starName', title: 'Звезды', render: (title) => title },
    { dataIndex: 'createdAt', title: 'Дата создания', render: (date) => new Date(date).toISOString().slice(0, 10) },
    {
      width: 50, title: () => <PoweroffOutlined />, dataIndex: '', key: 'toggle', render: renderDisabledToggleButton,
    },
    {
      width: 50, title: () => <EditOutlined />, dataIndex: '', key: 'EDIT', render: renderEditButton,
    },
  ];

  const tableHorizontalScroll = columns.reduce((sum, col) => (sum + col.width || 0), 0);

  if (!hotels.length) return <NoData />;

  return (
    <Table
      columns={columns}
      rowKey="id"
      dataSource={hotels}
      pagination={false}
      scroll={{ x: tableHorizontalScroll }}
    />
  );
};

export default HotelsTable;
