import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import {
  Button, Col, Row,
} from 'antd';

import FieldEditor from '../../common/components/formFields/FieldEditor';

const SearchNotes = ({ loading, edit, handleSubmit }) => {
  const [lang, setLang] = useState('uk');

  const onLangChange = (value) => setLang(value);

  return (
    <Row>
      <Col span={20} offset={2}>
        <div className="ant-btn-group">
          <button
            type="button"
            onClick={() => onLangChange('uk')}
            className={`ant-btn ant-btn ${lang === 'uk' ? 'ant-btn-primary' : ''}`}
          >
            <span>Uk</span>
          </button>

          <button
            type="button"
            onClick={() => onLangChange('ru')}
            className={`ant-btn ant-btn ${lang === 'ru' ? 'ant-btn-primary' : ''}`}
          >
            <span>Ru</span>
          </button>
        </div>

        <form onSubmit={handleSubmit}>
          <Row gutter={[8, 16]}>
            <Col span={24}>
              {(lang === 'uk')
              && <Field name="searchNotes.uk" addonBefore="Примечания к выдаче (укр)" component={FieldEditor} />}
              {(lang === 'ru')
              && <Field name="searchNotes.ru" addonBefore="Примечания к выдаче (рус)" component={FieldEditor} />}
            </Col>
          </Row>

          <Button type="primary" htmlType="submit" disabled={loading}>
            {edit ? 'Сохранить' : 'Добавить страну'}
          </Button>
        </form>
      </Col>
    </Row>
  );
};

export default SearchNotes;

SearchNotes.propTypes = {
  loading: PropTypes.bool.isRequired,
  edit: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};
