import React from 'react';
import PropTypes from 'prop-types';
import { Table, Radio } from 'antd';

const AttributesTable = ({ services, onChange }) => {
  function renderActions(...args) {
    const [, rowItem] = args;

    return (
      <Radio.Group defaultValue={rowItem.value || null} onChange={(event) => onChange(rowItem.key, event.target.value)}>
        {rowItem.possibleValues.map((element) => (
          <Radio value={element} key={element}>{element}</Radio>
        ))}

        <Radio value={null} key="null"><i>(Пустое)</i></Radio>
      </Radio.Group>
    );
  }

  const columns = [
    { dataIndex: 'name', title: 'Название', render: (name) => name.ru },
    {
      dataIndex: '', title: 'Значение', key: 'actions', render: renderActions,
    },
  ];

  const tableHorizontalScroll = columns.reduce((sum, col) => (sum + col.width || 0), 0);

  return (
    <Table
      columns={columns}
      rowKey={['name'].ru}
      dataSource={services}
      pagination={false}
      scroll={{ x: tableHorizontalScroll }}
    />
  );
};

export default AttributesTable;

AttributesTable.propTypes = {
  services: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};
