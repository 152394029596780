import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Tabs } from 'antd';
import * as Yup from 'yup';

import Country from './forms/Country';
import Visa from './forms/Visa';
import ReservationNotes from './forms/ReservationNotes';
import SearchNotes from './forms/SearchNotes';

const { TabPane } = Tabs;

const Form = ({
  edit, loading, initialValues, addCountry, editCountry,
}) => {
  const history = useHistory();
  const formSchema = Yup.object().shape({
    name: Yup.object().shape({
      ru: Yup.string().required(),
      uk: Yup.string().required(),
      en: Yup.string().required(),
    }),
  });

  function onSubmit(values) {
    if (edit) {
      return editCountry(values.id, values, () => history.push('/countries'));
    }

    return addCountry(values, () => history.push('/countries'));
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={formSchema}
      validateOnChange={false}
    >
      {({
        values,
        errors,
        touched,
        handleSubmit,
        handleBlur,
        setFieldValue,
      }) => (
        <Tabs defaultActiveKey="1">
          <TabPane tab="Страна" key="1">
            <Country
              loading={loading}
              edit={edit}
              values={values}
              errors={errors}
              touched={touched}
              handleBlur={handleBlur}
              handleSubmit={handleSubmit}
              setFieldValue={setFieldValue}
            />
          </TabPane>

          <TabPane tab="Виза" key="2">
            <Visa
              loading={loading}
              edit={edit}
              handleSubmit={handleSubmit}
            />
          </TabPane>

          <TabPane tab="Примечания к бронированию" key="3">
            <ReservationNotes
              loading={loading}
              edit={edit}
              handleSubmit={handleSubmit}
            />
          </TabPane>

          <TabPane tab="Примечания к выдаче" key="4">
            <SearchNotes
              loading={loading}
              edit={edit}
              handleSubmit={handleSubmit}
            />
          </TabPane>
        </Tabs>
      )}
    </Formik>
  );
};

export default Form;

Form.defaultProps = {
  initialValues: {
    name: {
      ru: '',
      uk: '',
      en: '',
    },
    default: false,
    disabled: false,
    popular: false,
    currency: 'USD',
    icon: '',
    andromedaId: [],
  },
  edit: false,
  loading: false,
  addCountry: () => { },
  editCountry: () => { },
};

Form.propTypes = {
  edit: PropTypes.bool,
  loading: PropTypes.bool,
  initialValues: PropTypes.shape({}),
  addCountry: PropTypes.func,
  editCountry: PropTypes.func,
};
