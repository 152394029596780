export const MENU_ITEMS = [
  { type: 'link', link: '/countries', title: 'Cтраны' },
  { type: 'link', link: '/regions', title: 'Регионы' },
  { type: 'link', link: '/cities', title: 'Города' },
  { type: 'link', link: '/services-groups', title: 'Группы сервисов' },
  { type: 'link', link: '/services', title: 'Сервисы' },
  { type: 'link', link: '/stars', title: 'Категории отелей' },
  { type: 'link', link: '/meals', title: 'Типы питания' },
  { type: 'link', link: '/departure-cities', title: 'Города отправления' },
  { type: 'link', link: '/hotels', title: 'Отели' },
  { type: 'link', link: '/tour-operators', title: 'Тур операторы' },
  { type: 'link', link: '/reviews', title: 'Отзывы' },
];
