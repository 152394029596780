import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Row } from 'antd';
import { useHistory } from 'react-router-dom';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';

import InputField from '../../common/components/formFields/InputField';
import { RoomsContext } from '../../rooms/Provider';

const AddRoomModal = ({ hotelId, modalVisible, setModalVisible }) => {
  const { addRoom } = useContext(RoomsContext);
  const history = useHistory();
  const formSchema = Yup.object().shape({
    title: Yup.object().shape({
      ru: Yup.string().required(),
      uk: Yup.string().required(),
    }),
    hotelId: Yup.string().required(),
  });

  async function onSubmit(values) {
    const room = await addRoom(values);

    if (!room) {
      return null;
    }

    return history.push(`/rooms/${room.id}/edit`);
  }

  return (
    <Modal
      title="Добавить комнату"
      visible={modalVisible}
      onCancel={() => setModalVisible(false)}
      footer={null}
    >
      <Formik
        initialValues={{ title: { ru: '', uk: '' }, hotelId }}
        onSubmit={onSubmit}
        validationSchema={formSchema}
        validateOnChange={false}
      >
        {({
          handleSubmit,
          errors,
          touched,
        }) => (
          <form onSubmit={handleSubmit}>
            <Row style={{ marginBottom: '10px' }}>
              <Field
                type="text"
                name="title.ru"
                addonBefore="Название комнаты (рус)"
                component={InputField}
                className={errors.title && errors.title.ru && touched.title.ru && 'ant-form-item-has-error'}
              />
            </Row>

            <Row style={{ marginBottom: '10px' }}>
              <Field
                type="text"
                name="title.uk"
                addonBefore="Название комнаты (укр)"
                component={InputField}
                className={errors.title && errors.title.uk && touched.title.uk && 'ant-form-item-has-error'}
              />
            </Row>

            <Row style={{ marginBottom: '10px' }}>
              <Field
                type="text"
                name="hotelId"
                addonBefore="ID отеля"
                value={hotelId}
                component={InputField}
                disabled
                className={errors.hotelId && touched.hotelId && 'ant-form-item-has-error'}
              />
            </Row>

            <Row>
              <Button type="primary" htmlType="submit">
                Добавить
              </Button>
            </Row>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

AddRoomModal.propTypes = {
  hotelId: PropTypes.string.isRequired,
  modalVisible: PropTypes.bool.isRequired,
  setModalVisible: PropTypes.func.isRequired,
};

export default AddRoomModal;
