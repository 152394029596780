import React from 'react';
import PropTypes from 'prop-types';
import RUG, { DragArea, Card } from 'react-upload-gallery';
import {
  Button, Col, Row,
} from 'antd';

import callApi from '../../utils/callApi';
import { CONTENT_URL } from '../../constants';

const CDN_PUBLIC_URL = process.env.REACT_APP_CDN_PUBLIC_URL;
const hotelsImagesPathPrefix = 'hotels';

const Images = ({
  loading, history, values, setFieldValue, handleSubmit,
}) => (
  <form onSubmit={handleSubmit}>
    <RUG
      action={`${CONTENT_URL}/images/hotels/${values.id}`}
      accept={['jpg', 'jpeg', 'png']}
      source={(response) => `${response}`}
      initialState={(values.images || []).map((image) => ({ source: `${image}` }))}
      onChange={(images) => setFieldValue('images', images.map((image) => image.source))}
      customRequest={async ({
        file,
        action,
        uid,
        onSuccess,
      }) => {
        const imageFormData = new FormData();

        imageFormData.append('image', file);

        const res = await callApi.post(action, imageFormData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        onSuccess(uid, res.data);

        return {
          abort: () => {},
        };
      }}
    >
      <DragArea className="rug-items __card __sorting">
        {
          (image) => {
            if (image.done) {
              const source = image.source.includes(hotelsImagesPathPrefix)
                ? image.source
                : `${CDN_PUBLIC_URL}/${hotelsImagesPathPrefix}${image.source}`;

              return (
                <Card
                  image={{
                    ...image,
                    source,
                  }}
                  style={{ height: 150 }}
                />
              );
            }

            return false;
          }
        }
      </DragArea>
    </RUG>

    <Row gutter={[8, 16]}>
      <Col>
        <Button type="secondary" onClick={() => history.push('/hotels')}>
          Отменить
        </Button>
      </Col>

      <Col>
        <Button type="primary" htmlType="submit" disabled={loading}>
          Сохранить
        </Button>
      </Col>
    </Row>
  </form>
);

export default Images;

Images.propTypes = {
  loading: PropTypes.bool.isRequired,
  history: PropTypes.any.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  values: PropTypes.shape({
    images: PropTypes.arrayOf(PropTypes.string),
    id: PropTypes.string,
  }).isRequired,
  setFieldValue: PropTypes.func.isRequired,
};
