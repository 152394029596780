import React, { useContext } from 'react';
import { Formik } from 'formik';
import { Tabs } from 'antd';
import * as Yup from 'yup';

import { ServicesContext } from '../services/Provider';
import { RoomsContext } from './Provider';
import Room from './forms/Room';
import Attributes from './forms/Attributes';
import Images from './forms/Images';
import formatImages from '../utils/formatImages';

const { TabPane } = Tabs;

const Form = () => {
  const { loading, currentRoom, editRoom } = useContext(RoomsContext);
  const { services, fetchServices } = useContext(ServicesContext);
  const formSchema = Yup.object().shape({
    title: Yup.object().shape({
      ru: Yup.string().required(),
      uk: Yup.string().required(),
    }),
    hotelId: Yup.string().required(),
  });

  function onSubmit(values) {
    const cleanedMatching = values.matching.map((elem) => elem.trim().toLowerCase());
    const formattedImages = formatImages(values.images);
    const newValues = {
      ...values,
      matching: cleanedMatching,
      images: formattedImages,
    };

    return editRoom(values.id, newValues, () => window.location.reload());
  }

  return (
    <Formik
      enableReinitialize
      initialValues={currentRoom}
      onSubmit={onSubmit}
      validationSchema={formSchema}
      validateOnChange={false}
    >
      {({
        values,
        errors,
        touched,
        handleSubmit,
        setFieldValue,
      }) => (
        <Tabs defaultActiveKey="1">
          <TabPane tab="Общая информация" key="1">
            <Room
              loading={loading}
              values={values}
              errors={errors}
              touched={touched}
              handleSubmit={handleSubmit}
            />
          </TabPane>

          <TabPane tab="Атрибуты" key="2">
            <Attributes
              loading={loading}
              values={values}
              services={services}
              fetchServices={fetchServices}
              setFieldValue={setFieldValue}
              handleSubmit={handleSubmit}
            />
          </TabPane>

          <TabPane tab="Изображения" key="3">
            <Images
              loading={loading}
              values={values}
              setFieldValue={setFieldValue}
              handleSubmit={handleSubmit}
            />
          </TabPane>
        </Tabs>
      )}
    </Formik>
  );
};

export default Form;
