import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NotificationManager } from 'react-notifications';

import {
  loginApi, logoutApi,
} from './api';
import getErrorMessage from '../utils/getErrorMessage';
import { getStorageValue, removeFromStorage, setStorageValue } from '../utils/storage';

const AuthContext = React.createContext();

const AuthProvider = ({ children }) => {
  const token = getStorageValue('token');
  const [loggedIn, setLoggedIn] = useState(!!token);
  const [loading, setLoading] = useState(false);

  const login = async (data, callback) => {
    try {
      const authData = await loginApi(data);
      const tokens = authData.data.result.tokens || {};

      setStorageValue('token', tokens.token, true);
      setStorageValue('refreshToken', tokens.refreshToken, true);

      setLoggedIn(true);
      setLoading(false);

      if (callback && typeof callback === 'function') {
        callback();
      }
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  const logout = async () => {
    try {
      const refreshToken = getStorageValue('refreshToken');

      await logoutApi({ refreshToken });

      removeFromStorage('token');
      removeFromStorage('refreshToken');

      window.location.href = '/login';
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  return (
    <AuthContext.Provider value={{
      loggedIn,
      loading,
      login,
      logout,
    }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.object.isRequired,
};

export { AuthProvider, AuthContext };
