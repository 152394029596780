import React, { useContext, useEffect } from 'react';
import { Spin, PageHeader, Pagination } from 'antd';

import { RegionsContext } from './Provider';
import RegionsTable from './Table';
import RegionsTopPanel from './TopPanel';

const Regions = () => {
  const {
    count, loading, searchParams, setSearchParams, fetchRegionsWithParentsNames,
  } = useContext(RegionsContext);

  function onChangePagination(page, pageSize) {
    const skip = (page - 1) * pageSize;

    setSearchParams({ ...searchParams, skip });
  }

  useEffect(() => {
    fetchRegionsWithParentsNames();
  }, [searchParams]);

  if (loading) {
    return <Spin tip="Loading..." size="large" />;
  }

  return (
    <div style={{ margin: 30 }}>
      <PageHeader className="site-page-header" title="Регионы" />
      <RegionsTopPanel />
      <RegionsTable />
      <Pagination
        showSizeChanger={false}
        current={(searchParams.skip / 20) + 1 || 1}
        defaultPageSize={20}
        total={count}
        onChange={onChangePagination}
      />
    </div>
  );
};

export default Regions;
