import React, { useContext } from 'react';
import { PageHeader, Row, Col } from 'antd';

import { AuthContext } from './Provider';
import Form from './Form';

const Auth = () => {
  const { loading, login } = useContext(AuthContext);

  return (
    <Row className="min-100vh" type="flex" align="middle" justify="center">
      <Col md={6} lg={5}>
        <PageHeader className="site-page-header" title="Войти" />
        <Form loading={loading} login={login} />
      </Col>
    </Row>
  );
};

export default Auth;
