import React from 'react';
import PropTypes from 'prop-types';
import {
  message,
  Modal,
  Row,
  Upload,
} from 'antd';
import { InboxOutlined } from '@ant-design/icons';

import { CONTENT_URL } from '../../constants';
import callApi from '../../utils/callApi';

const { Dragger } = Upload;

const MultiModal = ({ multiModalVisible, setMultiModalVisible }) => {
  const fileInputProps = {
    name: 'file',
    accept: '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
    multiple: false,
    action: `${CONTENT_URL}/hotels/sync/otpusk/multi`,
    async customRequest(params) {
      const {
        action,
        file,
        onSuccess,
        onError,
      } = params;

      try {
        const formData = new FormData();

        formData.append('file', file);

        await callApi.post(action, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        onSuccess();
        setMultiModalVisible(false);
        message.success('Отели импортировались');
      } catch (error) {
        onError(error);
        message.error('Не удалось импортировать отели');
      }
    },
  };

  return (
    <Modal
      title="Добавить отели"
      visible={multiModalVisible}
      onCancel={() => setMultiModalVisible(false)}
      footer={null}
    >
      <Row gutter={[8, 16]}>
        <Dragger {...fileInputProps}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Щелкните или перетащите файл в эту область, чтобы загрузить</p>
          <p className="ant-upload-hint">Только один файл расширение .csv</p>
        </Dragger>
      </Row>
    </Modal>
  );
};

MultiModal.propTypes = {
  multiModalVisible: PropTypes.bool.isRequired,
  setMultiModalVisible: PropTypes.func.isRequired,
};

export default MultiModal;
