import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';
import 'react-upload-gallery/dist/style.css';
import 'react-notifications/lib/notifications.css';
import 'antd/dist/antd.css';

import './App.css';
import MainLayout from './common/containers/MainLayout';
import { AuthProvider } from './auth/Provider';
import Auth from './auth';
import { CountriesProvider } from './countries/Provider';
import Countries from './countries';
import CountriesAddView from './countries/AddView';
import CountriesEditView from './countries/EditView';
import { RegionsProvider } from './regions/Provider';
import Regions from './regions';
import RegionsAddView from './regions/AddView';
import RegionsEditView from './regions/EditView';
import { CitiesProvider } from './cities/Provider';
import Cities from './cities';
import CitiesAddView from './cities/AddView';
import CitiesEditView from './cities/EditView';
import { ServicesGroupsProvider } from './servicesGroups/Provider';
import ServicesGroups from './servicesGroups';
import ServicesGroupsAddView from './servicesGroups/AddView';
import ServicesGroupsEditView from './servicesGroups/EditView';
import { ServicesProvider } from './services/Provider';
import Services from './services';
import ServicesAddView from './services/AddView';
import ServicesEditView from './services/EditView';
import { StarsProvider } from './stars/Provider';
import Stars from './stars';
import StarsAddView from './stars/AddView';
import StarsEditView from './stars/EditView';
import { MealsProvider } from './meals/Provider';
import Meals from './meals';
import MealsAddView from './meals/AddView';
import MealsEditView from './meals/EditView';
import { DepartureCitiesProvider } from './departureCities/Provider';
import DepartureCities from './departureCities';
import DepartureCitiesAddView from './departureCities/AddView';
import DepartureCitiesEditView from './departureCities/EditView';
import { RoomsProvider } from './rooms/Provider';
import RoomsEditView from './rooms/EditView';
import { ReviewsProvider } from './reviews/Provider';
import Reviews from './reviews';
import ReviewsEditView from './reviews/EditView';
import { HotelsProvider } from './hotels/Provider';
import Hotels from './hotels';
import HotelsEditView from './hotels/EditView';
import { TourOperatorsProvider } from './tourOperators/Provider';
import TourOperators from './tourOperators';
import TourOperatorsAddView from './tourOperators/AddView';
import TourOperatorsEditView from './tourOperators/EditView';

function App() {
  return (
    <AuthProvider>
      <CountriesProvider>
        <RegionsProvider>
          <CitiesProvider>
            <ServicesGroupsProvider>
              <ServicesProvider>
                <StarsProvider>
                  <MealsProvider>
                    <DepartureCitiesProvider>
                      <RoomsProvider>
                        <ReviewsProvider>
                          <HotelsProvider>
                            <TourOperatorsProvider>
                              <div className="App">
                                <Router>
                                  <Switch>
                                    <Route path="/login" exact>
                                      <Auth />
                                    </Route>
                                    <MainLayout>
                                      <Route path="/countries" exact>
                                        <Countries />
                                      </Route>
                                      <Route path="/countries/add" exact>
                                        <CountriesAddView />
                                      </Route>
                                      <Route path="/countries/:id/edit" exact>
                                        <CountriesEditView />
                                      </Route>

                                      <Route path="/regions" exact>
                                        <Regions />
                                      </Route>
                                      <Route path="/regions/add" exact>
                                        <RegionsAddView />
                                      </Route>
                                      <Route path="/regions/:id/edit" exact>
                                        <RegionsEditView />
                                      </Route>

                                      <Route path="/cities" exact>
                                        <Cities />
                                      </Route>
                                      <Route path="/cities/add" exact>
                                        <CitiesAddView />
                                      </Route>
                                      <Route path="/cities/:id/edit" exact>
                                        <CitiesEditView />
                                      </Route>

                                      <Route path="/services-groups" exact>
                                        <ServicesGroups />
                                      </Route>
                                      <Route path="/services-groups/add" exact>
                                        <ServicesGroupsAddView />
                                      </Route>
                                      <Route path="/services-groups/:id/edit" exact>
                                        <ServicesGroupsEditView />
                                      </Route>

                                      <Route path="/services" exact>
                                        <Services />
                                      </Route>
                                      <Route path="/services/add" exact>
                                        <ServicesAddView />
                                      </Route>
                                      <Route path="/services/:id/edit" exact>
                                        <ServicesEditView />
                                      </Route>

                                      <Route path="/stars" exact>
                                        <Stars />
                                      </Route>
                                      <Route path="/stars/add" exact>
                                        <StarsAddView />
                                      </Route>
                                      <Route path="/stars/:id/edit" exact>
                                        <StarsEditView />
                                      </Route>

                                      <Route path="/meals" exact>
                                        <Meals />
                                      </Route>
                                      <Route path="/meals/add" exact>
                                        <MealsAddView />
                                      </Route>
                                      <Route path="/meals/:id/edit" exact>
                                        <MealsEditView />
                                      </Route>

                                      <Route path="/departure-cities" exact>
                                        <DepartureCities />
                                      </Route>
                                      <Route path="/departure-cities/add" exact>
                                        <DepartureCitiesAddView />
                                      </Route>
                                      <Route path="/departure-cities/:id/edit" exact>
                                        <DepartureCitiesEditView />
                                      </Route>

                                      <Route path="/hotels" exact>
                                        <Hotels />
                                      </Route>
                                      <Route path="/hotels/:id/edit" exact>
                                        <HotelsEditView />
                                      </Route>

                                      <Route path="/tour-operators" exact>
                                        <TourOperators />
                                      </Route>

                                      <Route path="/tour-operators/add" exact>
                                        <TourOperatorsAddView />
                                      </Route>
                                      <Route path="/tour-operators/:id/edit" exact>
                                        <TourOperatorsEditView />
                                      </Route>

                                      <Route path="/rooms/:id/edit" exact>
                                        <RoomsEditView />
                                      </Route>

                                      <Route path="/reviews" exact>
                                        <Reviews />
                                      </Route>
                                      <Route path="/reviews/:id/edit" exact>
                                        <ReviewsEditView />
                                      </Route>
                                    </MainLayout>
                                  </Switch>
                                </Router>
                              </div>
                              <NotificationContainer />
                            </TourOperatorsProvider>
                          </HotelsProvider>
                        </ReviewsProvider>
                      </RoomsProvider>
                    </DepartureCitiesProvider>
                  </MealsProvider>
                </StarsProvider>
              </ServicesProvider>
            </ServicesGroupsProvider>
          </CitiesProvider>
        </RegionsProvider>
      </CountriesProvider>
    </AuthProvider>
  );
}

export default App;
