import React, { useContext } from 'react';
import { Layout } from 'antd';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import MainSider from './MainSider';
import { AuthContext } from '../../auth/Provider';

const {
  Header, Sider, Content,
} = Layout;

const MainLayout = ({ children }) => {
  const { loggedIn } = useContext(AuthContext);
  const history = useHistory();

  if (!loggedIn) {
    history.replace('/login');
  }

  return (
    <Layout style={{ height: '100vh' }}>
      <Sider>
        <MainSider />
      </Sider>
      <Layout>
        <Header>
          <div>Admin Content</div>
        </Header>
        <Content>{children}</Content>
      </Layout>
    </Layout>
  );
};

MainLayout.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};

export default MainLayout;
