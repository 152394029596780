import React, { useContext } from 'react';
import { Formik } from 'formik';
import {
  Input, Button, Col, Row, Select,
} from 'antd';
import { SearchOutlined, CloseCircleOutlined } from '@ant-design/icons';

import { ReviewsContext } from './Provider';

const { Option } = Select;

const TopPanel = () => {
  const { setSearchParams } = useContext(ReviewsContext);

  return (
    <div>
      <Formik
        initialValues={{}}
        onSubmit={(values) => setSearchParams(values)}
        onReset={(values) => setSearchParams(values)}
      >
        {({
          values, handleChange, handleBlur, handleSubmit, setFieldValue, resetForm,
        }) => (
          <form onSubmit={handleSubmit} onReset={resetForm}>
            <Row gutter={[8, 8]}>
              <Col flex="1 1 500px">
                <Row justify="start">
                  <Col span={4}>
                    <Input
                      type="text"
                      name="search"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.search}
                      placeholder="Отзывы"
                    />
                  </Col>

                  <Col span={4}>
                    <Select
                      name="type"
                      onChange={(value) => setFieldValue('type', value)}
                      onBlur={handleBlur}
                      defaultValue={values.type}
                      style={{ width: 100 }}
                      placeholder="Тип"
                    >
                      <Option value="">Все</Option>
                      <Option value="VseZdes">VseZdes</Option>
                      <Option value="Turpravda">Turpravda</Option>
                    </Select>
                  </Col>

                  <Col span={4}>
                    <Select
                      name="active"
                      onChange={(value) => setFieldValue('active', value)}
                      onBlur={handleBlur}
                      defaultValue={values.active}
                      style={{ width: 100 }}
                      placeholder="Включен"
                    >
                      <Option value="">Все</Option>
                      <Option value="true">Включен</Option>
                      <Option value="false">Выключен</Option>
                    </Select>
                  </Col>

                  <Col span={4}>
                    <Select
                      name="approved"
                      onChange={(value) => setFieldValue('approved', value)}
                      onBlur={handleBlur}
                      defaultValue={values.approved}
                      style={{ width: 150 }}
                      placeholder="Подтвержденные"
                    >
                      <Option value="">Все</Option>
                      <Option value="true">Подтвержденные</Option>
                      <Option value="false">Не подтвержденные</Option>
                    </Select>
                  </Col>

                  <Col span={2}>
                    <Button htmlType="submit" type="primary" icon={<SearchOutlined />}>
                      Найти
                    </Button>
                  </Col>

                  <Col span={2}>
                    <Button htmlType="reset" type="primary" icon={<CloseCircleOutlined />}>
                      Очистить
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default TopPanel;
