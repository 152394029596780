import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import {
  Button, Col, Row,
} from 'antd';
import * as Yup from 'yup';

import InputField from '../common/components/formFields/InputField';

const Form = ({
  edit, loading, initialValues, addServicesGroup, editServicesGroup,
}) => {
  const history = useHistory();
  const formSchema = Yup.object().shape({
    name: Yup.object().shape({
      ru: Yup.string().required(),
      uk: Yup.string().required(),
    }),
    key: Yup.string().required(),
  });

  function onSubmit(values) {
    if (edit) {
      return editServicesGroup(values.id, values, () => history.push('/services-groups'));
    }

    return addServicesGroup(values, () => history.push('/services-groups'));
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={formSchema}
      validateOnChange={false}
    >
      {({
        handleSubmit,
        errors,
        touched,
      }) => (
        <Row>
          <Col span={20} offset={2}>
            <form onSubmit={handleSubmit}>
              <Row gutter={[8, 16]}>
                <Col>
                  <Field
                    type="text"
                    name="name.ru"
                    addonBefore="Название группы сервисов (рус)"
                    component={InputField}
                    className={errors.name && errors.name.ru && touched.name.ru && 'ant-form-item-has-error'}
                  />
                </Col>

                <Col>
                  <Field
                    type="text"
                    name="name.uk"
                    addonBefore="Название группы сервисов (укр)"
                    component={InputField}
                    className={errors.name && errors.name.uk && touched.name.uk && 'ant-form-item-has-error'}
                  />
                </Col>

                <Col>
                  <Field
                    type="text"
                    name="key"
                    addonBefore="Ключ группы сервисов"
                    component={InputField}
                    className={errors.key && touched.key && 'ant-form-item-has-error'}
                  />
                </Col>
              </Row>

              <Button type="primary" htmlType="submit" disabled={edit && loading}>
                {edit ? 'Сохранить' : 'Добавить группу сервисов'}
              </Button>
            </form>
          </Col>
        </Row>
      )}
    </Formik>
  );
};

Form.defaultProps = {
  initialValues: {
    name: {
      ru: '',
      uk: '',
    },
    key: '',
  },
  edit: false,
  loading: false,
  addServicesGroup: () => {},
  editServicesGroup: () => {},
};

Form.propTypes = {
  edit: PropTypes.bool,
  loading: PropTypes.bool,
  initialValues: PropTypes.shape({}),
  addServicesGroup: PropTypes.func,
  editServicesGroup: PropTypes.func,
};

export default Form;
