/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const FieldCheckbox = (props) => {
  const {
    field, addonBefore, form: { touched, errors }, title,
  } = props;
  const inputClassName = classnames('form-check-input', { 'is-invalid hasError': (touched[field.name] && errors[field.name]) });
  const { value } = field;

  return (
    <div className="mb-3">
      {title && <label>{title}</label>}
      <div className="form-check">
        <input className={inputClassName} type="checkbox" {...field} value={!!value} id={addonBefore} checked={!!value} />
        {addonBefore && (
          <label className="form-check-label" htmlFor={addonBefore}>{addonBefore}</label>
        )}
      </div>
    </div>
  );
};

FieldCheckbox.propTypes = {
  title: PropTypes.string,
  field: PropTypes.shape({}).isRequired,
};

FieldCheckbox.defaultProps = {
  title: '',
};

export default FieldCheckbox;
