import React, {
  useContext, useEffect, useState,
} from 'react';
import { Field, Formik } from 'formik';
import {
  Input, Select, Button, Col, Row,
} from 'antd';
import { SearchOutlined, CloseCircleOutlined } from '@ant-design/icons';

import { HotelsContext } from './Provider';
import SingleModal from './modals/SingleModal';
import MultiModal from './modals/MultiModal';
import FieldCheckbox from '../common/components/formFields/FieldCheckbox';
import { CountriesContext } from '../countries/Provider';
import { CitiesContext } from '../cities/Provider';
import { StarsContext } from '../stars/Provider';

const { Option } = Select;

const TopPanel = () => {
  const { searchParams, setSearchParams, addHotel } = useContext(HotelsContext);
  const { countries, fetchCountries } = useContext(CountriesContext);
  const { cities, fetchCities } = useContext(CitiesContext);
  const { stars, fetchStars } = useContext(StarsContext);
  const [singleModalVisible, setSingleModalVisible] = useState(false);
  const [multiModalVisible, setMultiModalVisible] = useState(false);

  useEffect(() => {
    fetchCountries({ limit: 0 });
    fetchStars({ limit: 0 });
  }, []);

  const sort = (items) => items.sort((a, b) => a.name.ru.localeCompare(b.name.ru));

  return (
    <div>
      <Formik
        initialValues={{}}
        onSubmit={(values) => setSearchParams({ ...searchParams, ...values })}
        onReset={(values) => setSearchParams({ ...searchParams, ...values })}
      >
        {({
          values, handleChange, handleBlur, handleSubmit, setFieldValue, resetForm,
        }) => (
          <form onSubmit={handleSubmit} onReset={resetForm}>
            <Row gutter={[8, 8]}>
              <Col flex="1 1 500px">
                <Row justify="start">
                  <Col span={3}>
                    <Input
                      type="text"
                      name="search"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.search}
                      placeholder="Отель"
                    />
                  </Col>

                  <Col span={3}>
                    <Select
                      name="countryId"
                      onChange={(value) => {
                        setFieldValue('countryId', value);
                        fetchCities({ countryId: value, limit: 0 });
                      }}
                      onBlur={handleBlur}
                      placeholder="Страна"
                      style={{ width: 125 }}
                    >
                      {sort(countries).map((country) => (
                        <Option value={country.id} key={country.id}>{country.name.ru}</Option>
                      ))}
                    </Select>
                  </Col>

                  <Col span={3}>
                    <Select
                      name="cityId"
                      onChange={(value) => setFieldValue('cityId', value)}
                      onBlur={handleBlur}
                      placeholder="Город"
                      style={{ width: 125 }}
                      disabled={!cities.length}
                    >
                      {sort(cities).map((city) => (
                        <Option value={city.id} key={city.id}>{city.name.ru}</Option>
                      ))}
                    </Select>
                  </Col>

                  <Col span={3}>
                    <Select
                      name="starId"
                      onChange={(value) => setFieldValue('starId', value)}
                      onBlur={handleBlur}
                      placeholder="Звездность"
                      style={{ width: 120 }}
                    >
                      {sort(stars).map((star) => (
                        <Option value={star.id} key={star.id}>{star.name.ru}</Option>
                      ))}
                    </Select>
                  </Col>

                  <Col span={3}>
                    <Select
                      name="disabled"
                      onChange={(value) => setFieldValue('disabled', value)}
                      onBlur={handleBlur}
                      defaultValue={values.disabled}
                      style={{ width: 120 }}
                      placeholder="Включен"
                    >
                      <Option value="false">Включен</Option>
                      <Option value="true">Выключен</Option>
                    </Select>
                  </Col>

                  <Col span={3}>
                    <Field
                      name="status"
                      component={FieldCheckbox}
                      type="checkbox"
                      addonBefore="Новый"
                    />
                  </Col>

                  <Col span={2}>
                    <Button htmlType="submit" type="primary" icon={<SearchOutlined />}>
                      Найти
                    </Button>
                  </Col>

                  <Col span={2}>
                    <Button htmlType="reset" type="primary" icon={<CloseCircleOutlined />}>
                      Очистить
                    </Button>
                  </Col>
                </Row>
              </Col>

              <Col flex="0 1 100px">
                <Button type="primary" onClick={() => setSingleModalVisible(true)}>
                  Добавить отель
                </Button>
              </Col>

              <Col flex="0 1 100px">
                <Button style={{ color: 'white', backgroundColor: '#008337' }} onClick={() => setMultiModalVisible(true)}>
                  Добавить отели
                </Button>
              </Col>
            </Row>
          </form>
        )}
      </Formik>

      <SingleModal
        countries={countries}
        cities={cities}
        addHotel={addHotel}
        fetchCities={fetchCities}
        singleModalVisible={singleModalVisible}
        setSingleModalVisible={setSingleModalVisible}
      />

      <MultiModal
        multiModalVisible={multiModalVisible}
        setMultiModalVisible={setMultiModalVisible}
      />
    </div>
  );
};

export default TopPanel;
