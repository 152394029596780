import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Spin, PageHeader } from 'antd';
import _pick from 'lodash.pick';
import _omit from 'lodash.omit';

import Form from './Form';
import { HotelsContext } from './Provider';

const EditView = () => {
  const { id } = useParams();
  const {
    loading, currentHotel, getHotel,
  } = useContext(HotelsContext);

  useEffect(() => {
    getHotel(id);
  }, []);

  const operatorKeys = [
    'andromedaId',
    'anexId',
    'artId',
    'funAndSunId',
    'lotiId',
    'spaceId',
    'otpuskId',
  ];

  if (loading || !currentHotel) {
    return <Spin tip="Loading..." size="large" />;
  }

  const ids = _pick(currentHotel, operatorKeys);
  const links = [];

  Object.keys(ids).forEach((key) => (
    ids[key].forEach((linkId) => links.push({
      operator: key,
      id: linkId,
    }))
  ));

  const initialValues = {
    ..._omit(currentHotel, operatorKeys),
    links,
  };

  return (
    <div style={{ margin: 30 }}>
      <PageHeader className="site-page-header" title="Редактировать отель" />
      <Form initialValues={initialValues} operatorKeys={operatorKeys} />
    </div>
  );
};

export default EditView;
