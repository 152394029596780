import React from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray } from 'formik';
import {
  Select, Button, Col, Row, Popconfirm,
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import InputField from '../../common/components/formFields/InputField';

const { Option } = Select;

const Country = ({
  loading, edit, values, errors, touched, setFieldValue, handleBlur, handleSubmit,
}) => {
  function renderFieldArray(arrayHelpers, fields) {
    return (
      <>
        <Row gutter={[4, 16]}>
          <Col>
            <span style={{ verticalAlign: 'middle' }}>AndromedaId*</span>
          </Col>

          <Col>
            <Button type="primary" onClick={() => arrayHelpers.insert(0, '')}>Добавить еще</Button>
          </Col>
        </Row>

        {fields.map((field, index) => (
          <Row gutter={[24, 16]} key={index} className="inlineFieldsRowWrap">
            <Col>
              <Row gutter={4}>
                <Col>
                  <Field name={`andromedaId.${index}`} component={InputField} type="number" classes="form-control" />
                </Col>

                <Col>
                  <Popconfirm
                    okText="Yes"
                    cancelText="No"
                    placement="leftTop"
                    onConfirm={() => arrayHelpers.remove(index)}
                    title="Вы точно уверены что хотите удалить этот andromedaId?"
                  >
                    <Button type="danger" icon={<DeleteOutlined />} />
                  </Popconfirm>
                </Col>
              </Row>
            </Col>
          </Row>
        ))}
      </>
    );
  }

  const alias = edit
    ? <Col><Field name="alias" component={InputField} type="text" addonBefore="Alias" disabled /></Col>
    : null;

  return (
    <Row>
      <Col span={20} offset={2}>
        <form onSubmit={handleSubmit}>
          <Row gutter={[8, 16]}>
            <Col>
              <Field
                type="text"
                name="name.ru"
                addonBefore="Название страны (рус)"
                component={InputField}
                className={
                  errors.name && touched.name && errors.name.ru && touched.name.ru && 'ant-form-item-has-error'
                }
              />
            </Col>

            <Col>
              <Field
                type="text"
                name="name.uk"
                addonBefore="Название страны (укр)"
                component={InputField}
                className={
                  errors.name && touched.name && errors.name.uk && touched.name.uk && 'ant-form-item-has-error'
                }
              />
            </Col>


            <Col>
              <Field
                type="text"
                name="name.en"
                addonBefore="Название страны (анг)"
                component={InputField}
                className={
                  errors.name && touched.name && errors.name.en && touched.name.en && 'ant-form-item-has-error'
                }
              />
            </Col>
          </Row>

          <Row gutter={[8, 16]}>
            <Col>
              <Field
                type="text"
                name="code"
                addonBefore="Код страны ISO2"
                placeholder="UA"
                component={InputField}
              />
            </Col>

            {alias}
          </Row>

          <Row gutter={[8, 16]}>
            <Col>
              <Select
                name="currency"
                onChange={(value) => setFieldValue('currency', value)}
                onBlur={handleBlur}
                defaultValue={values.currency}
                style={{ width: 100 }}
              >
                <Option value="USD">USD</Option>
                <Option value="EUR">EUR</Option>
                <Option value="RUB">RUB</Option>
              </Select>
            </Col>

            <Col>
              <Select
                name="default"
                onChange={(value) => setFieldValue('default', value)}
                onBlur={handleBlur}
                defaultValue={values.default}
                style={{ width: 170 }}
              >
                <Option value>По умолчанию</Option>
                <Option value={false}>Не по умолчанию</Option>
              </Select>
            </Col>

            <Col>
              <Select
                name="disabled"
                onChange={(value) => setFieldValue('disabled', value)}
                onBlur={handleBlur}
                defaultValue={values.disabled}
                style={{ width: 120 }}
              >
                <Option value={false}>Включен</Option>
                <Option value>Выключен</Option>
              </Select>
            </Col>

            <Col>
              <Select
                name="popular"
                onChange={(value) => setFieldValue('popular', value)}
                onBlur={handleBlur}
                defaultValue={values.popular}
                style={{ width: 150 }}
              >
                <Option value>Популярная</Option>
                <Option value={false}>Не популярная</Option>
              </Select>
            </Col>
          </Row>

          <Row gutter={[8, 16]}>
            <Col>
              <FieldArray
                name="andromedaId"
                render={(arrayHelpers) => renderFieldArray(arrayHelpers, values.andromedaId)}
              />
            </Col>
          </Row>

          <Button type="primary" htmlType="submit" disabled={loading}>
            {edit ? 'Сохранить' : 'Добавить страну'}
          </Button>
        </form>
      </Col>
    </Row>
  );
};

export default Country;

Country.propTypes = {
  loading: PropTypes.bool.isRequired,
  edit: PropTypes.bool.isRequired,
  values: PropTypes.shape({
    currency: PropTypes.string,
    default: PropTypes.bool,
    disabled: PropTypes.bool,
    popular: PropTypes.bool,
    andromedaId: PropTypes.array,
  }).isRequired,
  errors: PropTypes.shape({
    name: PropTypes.shape({
      ru: PropTypes.string,
      uk: PropTypes.string,
      en: PropTypes.string,
    }),
  }).isRequired,
  touched: PropTypes.shape({
    name: PropTypes.shape({
      ru: PropTypes.string,
      uk: PropTypes.string,
      en: PropTypes.string,
    }),
  }).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};
