import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import {
  Button, Col, Row,
} from 'antd';

import FieldCheckbox from '../../common/components/formFields/FieldCheckbox';
import FieldEditor from '../../common/components/formFields/FieldEditor';

const Visa = ({ loading, edit, handleSubmit }) => {
  const [lang, setLang] = useState('uk');

  const onLangChange = (value) => setLang(value);

  return (
    <Row>
      <Col span={20} offset={2}>
        <div className="ant-btn-group">
          <button
            type="button"
            onClick={() => onLangChange('uk')}
            className={`ant-btn ant-btn ${lang === 'uk' ? 'ant-btn-primary' : ''}`}
          >
            <span>Uk</span>
          </button>

          <button
            type="button"
            onClick={() => onLangChange('ru')}
            className={`ant-btn ant-btn ${lang === 'ru' ? 'ant-btn-primary' : ''}`}
          >
            <span>Ru</span>
          </button>

          <button
            type="button"
            onClick={() => onLangChange('en')}
            className={`ant-btn ant-btn ${lang === 'en' ? 'ant-btn-primary' : ''}`}
          >
            <span>En</span>
          </button>
        </div>

        <form onSubmit={handleSubmit}>
          <Row gutter={[8, 16]}>
            <Col>
              <Field name="visa" type="checkbox" addonBefore="Нужна ли виза" component={FieldCheckbox} />
            </Col>

            <Col span={24}>
              {(lang === 'uk') && <Field name="visaText.uk" addonBefore="Текст визы (укр)" component={FieldEditor} />}
              {(lang === 'ru') && <Field name="visaText.ru" addonBefore="Текст визы (рус)" component={FieldEditor} />}
              {(lang === 'en') && <Field name="visaText.en" addonBefore="Текст визы (анг)" component={FieldEditor} />}
            </Col>
          </Row>

          <Button type="primary" htmlType="submit" disabled={loading}>
            {edit ? 'Сохранить' : 'Добавить страну'}
          </Button>
        </form>
      </Col>
    </Row>
  );
};

export default Visa;

Visa.propTypes = {
  loading: PropTypes.bool.isRequired,
  edit: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};
