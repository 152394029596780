import callApi from '../utils/callApi';
import { CONTENT_URL } from '../constants';

export const listHotelsApi = (params) => callApi.get(`${CONTENT_URL}/hotels`, { params });
export const getHotelApi = (id) => callApi.get(`${CONTENT_URL}/hotels/${id}`);
export const addHotelApi = (data) => callApi.post(`${CONTENT_URL}/hotels`, data);
export const editHotelApi = (id, data) => callApi.patch(`${CONTENT_URL}/hotels/${id}`, data);
export const deleteHotelApi = (id) => callApi.delete(`${CONTENT_URL}/hotels/${id}`);
export const syncHotelWithTripAdvisorApi = (data) => callApi.post(`${CONTENT_URL}/hotels/sync/tripadvisor`, data);
export const syncHotelWithOtpuskApi = (data) => callApi.post(`${CONTENT_URL}/hotels/sync/otpusk`, data);
