/* eslint-disable react/prop-types */
import React from 'react';
import { Input } from 'antd';

const { TextArea } = Input;

const FieldTextarea = ({ field, addonBefore, ...other }) => (
  <div>
    {addonBefore && <span>{addonBefore}</span>}
    <TextArea
      {...field}
      {...other}
    />
  </div>
);

export default FieldTextarea;
