import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Table, Tag, Popconfirm } from 'antd';
import {
  EditOutlined, DeleteOutlined, SettingOutlined, CheckOutlined, MinusOutlined, PoweroffOutlined,
} from '@ant-design/icons';

import NoData from '../common/components/NoData';
import ActionButton from '../common/components/ActionButton';
import { DepartureCitiesContext } from './Provider';

const DepartureCitiesTable = () => {
  const history = useHistory();
  const { departureCities, editDepartureCity, deleteDepartureCity } = useContext(DepartureCitiesContext);

  function renderMakeDefaultToggleButton(...args) {
    const [, rowItem] = args;
    const { default: isDefault, disabled } = rowItem;

    const icon = isDefault ? <CheckOutlined /> : <MinusOutlined />;
    const confirmMessage = isDefault ? '' : 'Вы уверены, что хотите сделать этот город городом по умолчанию?';
    const tooltip = disabled
      ? 'Пожалуйста, активируйте город, чтобы установить его по умолчанию'
      : 'Сделать городом по умолчанию?';

    return (
      <Popconfirm
        okText="Да"
        cancelText="Нет"
        placement="leftTop"
        title={confirmMessage}
        onConfirm={() => editDepartureCity(rowItem.id, { ...rowItem, default: !isDefault })}
      >
        <ActionButton icon={icon} tooltip={tooltip} disabled={isDefault || disabled} />
      </Popconfirm>
    );
  }

  function renderDisabledToggleButton(...args) {
    const [, rowItem] = args;
    const { disabled, default: isDefault } = rowItem;

    const icon = !disabled ? <CheckOutlined /> : <MinusOutlined />;
    let tooltip;

    if (isDefault) {
      tooltip = 'Пожалуйста, выберите другой город по умолчанию перед отключением этого города';
    } else if (disabled) {
      tooltip = 'Включить';
    } else {
      tooltip = 'Выключить';
    }

    return (
      <ActionButton
        icon={icon}
        tooltip={tooltip}
        disabled={isDefault}
        onClick={() => editDepartureCity(rowItem.id, { ...rowItem, disabled: !disabled })}
      />
    );
  }

  function renderEditButton(...args) {
    const [, rowItem] = args;

    return (
      <ActionButton
        type="primary"
        icon={<EditOutlined />}
        tooltip="Править"
        className="editAction"
        onClick={() => history.push(`/departure-cities/${rowItem.id}/edit`)}
      />
    );
  }

  function renderDeleteButton(...args) {
    const [, rowItem] = args;
    const { default: isDefault, id } = rowItem;
    const tooltip = isDefault
      ? 'Пожалуйста, выберите другой город по умолчанию перед удаление этого города'
      : 'Удалить';
    const deleteConfirm = 'Вы уверены, что хотите удалить этот город?';

    return (
      <Popconfirm
        okText="Да"
        cancelText="Нет"
        placement="leftTop"
        title={deleteConfirm}
        onConfirm={() => deleteDepartureCity(id)}
      >
        <ActionButton
          type="danger"
          icon={<DeleteOutlined />}
          tooltip={tooltip}
          className="deleteAction"
          disabled={isDefault}
        />
      </Popconfirm>
    );
  }

  function renderTitleCell(title, item) {
    return (
      <>
        {title.ru}
        {item.default ? <Tag color="#87d068" onClick={() => {}}>Default departure city</Tag> : null}
      </>
    );
  }

  const columns = [
    { dataIndex: 'name', title: 'Название', render: renderTitleCell },
    { dataIndex: 'id', title: 'ID' },
    {
      width: 50, title: () => <SettingOutlined />, dataIndex: '', key: 'default', render: renderMakeDefaultToggleButton,
    },
    {
      width: 50, title: () => <PoweroffOutlined />, dataIndex: '', key: 'toggle', render: renderDisabledToggleButton,
    },
    {
      width: 50, title: () => <EditOutlined />, dataIndex: '', key: 'EDIT', render: renderEditButton,
    },
    {
      width: 50, title: () => <DeleteOutlined />, dataIndex: '', key: 'DELETE', render: renderDeleteButton,
    },
  ];

  const tableHorizontalScroll = columns.reduce((sum, col) => (sum + col.width || 0), 0);

  if (!departureCities.length) return <NoData />;

  return (
    <Table
      columns={columns}
      rowKey="id"
      dataSource={departureCities}
      pagination={false}
      scroll={{ x: tableHorizontalScroll }}
    />
  );
};

export default DepartureCitiesTable;
