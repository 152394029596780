import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Field } from 'formik';
import { Col, Row, Button } from 'antd';

import InputField from '../../common/components/formFields/InputField';
import FieldCheckbox from '../../common/components/formFields/FieldCheckbox';
import FieldTextarea from '../../common/components/formFields/FieldTextarea';

const Review = ({
  loading, errors, touched, hotelId, handleSubmit,
}) => (
  <Row>
    <Col span={20} offset={2}>
      <form onSubmit={handleSubmit}>
        <Row gutter={[8, 16]}>
          <Col>
            <Link to={`/hotels/${hotelId}/edit`}>Hotel admin panel page</Link>
          </Col>
        </Row>

        <Row gutter={[8, 16]}>
          <Col>
            <Field
              type="text"
              name="hotelId"
              addonBefore="ID отеля"
              component={InputField}
              disabled
              className={errors.hotelId && touched.hotelId && 'ant-form-item-has-error'}
            />
          </Col>

          <Col>
            <Field
              type="text"
              name="type"
              addonBefore="Тип отзыва"
              component={InputField}
              disabled
              className={errors.type && touched.type && 'ant-form-item-has-error'}
            />
          </Col>
        </Row>

        <Row gutter={[8, 16]}>
          <Col>
            <Field
              type="text"
              name="content.author"
              addonBefore="Автор отзыва"
              component={InputField}
              className={errors.content && errors.content.author && touched.content.author && 'ant-form-item-has-error'}
            />
          </Col>

          <Col>
            <Field
              type="text"
              name="content.title"
              addonBefore="Заголовок отзыва"
              component={InputField}
              className={errors.content && errors.content.title && touched.content.title && 'ant-form-item-has-error'}
            />
          </Col>
        </Row>

        <Row gutter={[8, 16]}>
          <Col span={24}>
            <Field
              type="text"
              name="content.text"
              addonBefore="Текст отзыва"
              component={FieldTextarea}
              rows={8}
              className={errors.content && errors.content.text && touched.content.text && 'ant-form-item-has-error'}
            />
          </Col>
        </Row>

        <Row gutter={[8, 16]}>
          <Col>
            <Field
              name="active"
              component={FieldCheckbox}
              type="checkbox"
              addonBefore="Включен"
            />
          </Col>

          <Col>
            <Field
              name="approved"
              component={FieldCheckbox}
              type="checkbox"
              addonBefore="Подтвержден"
            />
          </Col>
        </Row>

        <Row gutter={[8, 16]}>
          <Col>
            <Button type="primary" htmlType="submit" disabled={loading}>
              Сохранить
            </Button>
          </Col>
        </Row>
      </form>
    </Col>
  </Row>
);

export default Review;

Review.propTypes = {
  loading: PropTypes.bool.isRequired,
  errors: PropTypes.shape({
    type: PropTypes.object,
    hotelId: PropTypes.string,
    content: PropTypes.shape({
      author: PropTypes.string,
      title: PropTypes.string,
      text: PropTypes.string,
    }),
  }).isRequired,
  touched: PropTypes.shape({
    type: PropTypes.object,
    hotelId: PropTypes.bool,
    content: PropTypes.shape({
      author: PropTypes.string,
      title: PropTypes.string,
      text: PropTypes.string,
    }),
  }).isRequired,
  hotelId: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};
