import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'formik';
import {
  Button, Col, Input, Row, Select,
} from 'antd';

import LinksTable from './LinksTable';

const { Option } = Select;

const Links = ({
  loading, history, values, operator, id, operatorKeys, setId, setOperator, handleSubmit,
  syncHotelWithOtpusk, otpuskConfirmModal, setOtpuskConfirmModal,
}) => {
  async function synchronizeOtpuskHotel(otpuskId) {
    const otpuskHotelData = await syncHotelWithOtpusk({ hotelId: values.id, otpuskId });

    if (!otpuskHotelData) {
      return setOtpuskConfirmModal({
        ...otpuskConfirmModal,
        confirmLoading: false,
      });
    }

    return setOtpuskConfirmModal({
      ...otpuskConfirmModal,
      visible: false,
      disabledSyncButton: true,
      synced: true,
    });
  }

  function renderFieldArray(arrayHelpers, fields) {
    return (
      <>
        <Row>
          <Col>
            <Select
              onChange={setOperator}
              style={{ width: 120 }}
              placeholder="Выбрать ТО"
            >
              {operatorKeys.map((item) => (
                <Option key={item} value={item}>{item}</Option>
              ))}
            </Select>
          </Col>

          <Col>
            <Input
              onChange={(event) => setId(event.target.value)}
              disabled={!operator}
              type="number"
            />
          </Col>

          <Col>
            <Button
              onClick={() => arrayHelpers.push({
                operator,
                id,
              })}
              disabled={!operator && !id}
            >
              Добавить
            </Button>
          </Col>
        </Row>

        <LinksTable
          items={fields}
          onDelete={(index) => arrayHelpers.remove(index)}
          synchronizeOtpuskHotel={synchronizeOtpuskHotel}
          otpuskConfirmModal={otpuskConfirmModal}
          setOtpuskConfirmModal={setOtpuskConfirmModal}
        />
      </>
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      <FieldArray
        name="links"
        render={(arrayHelpers) => renderFieldArray(arrayHelpers, values.links)}
      />

      <Row gutter={[8, 16]}>
        <Col>
          <Button type="secondary" onClick={() => history.push('/hotels')}>
            Отменить
          </Button>
        </Col>

        <Col>
          <Button type="primary" htmlType="submit" disabled={loading}>
            Сохранить
          </Button>
        </Col>
      </Row>
    </form>
  );
};

export default Links;

Links.propTypes = {
  loading: PropTypes.bool.isRequired,
  history: PropTypes.any.isRequired,
  values: PropTypes.shape({
    id: PropTypes.string,
    links: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  operator: PropTypes.string,
  id: PropTypes.string,
  operatorKeys: PropTypes.array,
  setId: PropTypes.func.isRequired,
  setOperator: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  syncHotelWithOtpusk: PropTypes.func.isRequired,
  otpuskConfirmModal: PropTypes.shape({}).isRequired,
  setOtpuskConfirmModal: PropTypes.func.isRequired,
};

Links.defaultProps = {
  operator: null,
  id: null,
  operatorKeys: [],
};
