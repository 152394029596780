import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import {
  Button, Col, Row,
} from 'antd';

import FieldEditor from '../../common/components/formFields/FieldEditor';

const Descriptions = ({
  loading, history, handleSubmit,
}) => {
  const [lang, setLang] = useState('uk');

  const onLangChange = (value) => setLang(value);

  return (
    <Row>
      <Col span={20} offset={2}>
        <div className="ant-btn-group">
          <button
            type="button"
            onClick={() => onLangChange('uk')}
            className={`ant-btn ant-btn ${lang === 'uk' ? 'ant-btn-primary' : ''}`}
          >
            <span>Uk</span>
          </button>

          <button
            type="button"
            onClick={() => onLangChange('ru')}
            className={`ant-btn ant-btn ${lang === 'ru' ? 'ant-btn-primary' : ''}`}
          >
            <span>Ru</span>
          </button>
        </div>

        <form onSubmit={handleSubmit}>
          <Row gutter={[8, 16]}>
            <Col span={24}>
              {(lang === 'uk')
                && <Field name="descriptions.hotel.uk" addonBefore="Описание отеля (укр)" component={FieldEditor} />}
              {(lang === 'ru')
                && <Field name="descriptions.hotel.ru" addonBefore="Описание отеля (рус)" component={FieldEditor} />}
            </Col>

            <Col span={24}>
              {(lang === 'uk')
                && <Field name="descriptions.disposition.uk" addonBefore="Описание расположение (укр)" component={FieldEditor} />}
              {(lang === 'ru')
                && <Field name="descriptions.disposition.ru" addonBefore="Описание расположение (рус)" component={FieldEditor} />}
            </Col>

            <Col span={24}>
              {(lang === 'uk')
                && <Field name="descriptions.capt.uk" addonBefore="Описание вместительноси (укр)" component={FieldEditor} />}
              {(lang === 'ru')
                && <Field name="descriptions.capt.ru" addonBefore="Описание вместительноси (рус)" component={FieldEditor} />}
            </Col>

            <Col span={24}>
              {(lang === 'uk')
                && <Field name="descriptions.beach.uk" addonBefore="Описание пляжа (укр)" component={FieldEditor} />}
              {(lang === 'ru')
                && <Field name="descriptions.beach.ru" addonBefore="Описание пляжа (рус)" component={FieldEditor} />}
            </Col>

            <Col span={24}>
              {(lang === 'uk')
                && <Field name="descriptions.child.uk" addonBefore="Описание детских удобств (укр)" component={FieldEditor} />}
              {(lang === 'ru')
                && <Field name="descriptions.child.ru" addonBefore="Описание детских удобств (рус)" component={FieldEditor} />}
            </Col>

            <Col span={24}>
              {(lang === 'uk')
                && <Field name="descriptions.sport.uk" addonBefore="Описание спортивных удобств (укр)" component={FieldEditor} />}
              {(lang === 'ru')
                && <Field name="descriptions.sport.ru" addonBefore="Описание спортивных удобств (рус)" component={FieldEditor} />}
            </Col>

            <Col span={24}>
              {(lang === 'uk')
                && <Field name="descriptions.meals.uk" addonBefore="Описание концепции питания (укр)" component={FieldEditor} />}
              {(lang === 'ru')
                && <Field name="descriptions.meals.ru" addonBefore="Описание концепции питания (рус)" component={FieldEditor} />}
            </Col>

            <Col span={24}>
              {(lang === 'uk')
                && <Field name="descriptions.rooms.uk" addonBefore="Описание номеров (укр)" component={FieldEditor} />}
              {(lang === 'ru')
                && <Field name="descriptions.rooms.ru" addonBefore="Описание номеров (рус)" component={FieldEditor} />}
            </Col>
          </Row>

          <Row gutter={[8, 16]}>
            <Col>
              <Button type="secondary" onClick={() => history.push('/hotels')}>
                Отменить
              </Button>
            </Col>

            <Col>
              <Button type="primary" htmlType="submit" disabled={loading}>
                Сохранить
              </Button>
            </Col>
          </Row>
        </form>
      </Col>
    </Row>
  );
};

export default Descriptions;

Descriptions.propTypes = {
  loading: PropTypes.bool.isRequired,
  history: PropTypes.any.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};
