import React, { useContext } from 'react';
import { PageHeader } from 'antd';

import { CountriesContext } from './Provider';
import Form from './Form';

const AddView = () => {
  const { addCountry } = useContext(CountriesContext);

  return (
    <div>
      <PageHeader className="site-page-header" title="Добавить страну" />
      <Form addCountry={addCountry} />
    </div>
  );
};

export default AddView;
