const getIndexesOfCharInString = (string, char) => {
  const indexes = [];

  for (let i = 0; i < string.length; i += 1) {
    if (string[i] === char) indexes.push(i);
  }

  return indexes;
};

const formatImages = (images, slashesToLeave = 2) => images.map((image) => {
  const indexes = getIndexesOfCharInString(image, '/');

  return image.slice(indexes[indexes.length - slashesToLeave]);
});

export default formatImages;
