const getSearchParams = (searchParams) => {
  const params = {};

  Object.keys(searchParams).forEach((key) => {
    if (searchParams[key] || (key === 'status' && typeof searchParams[key] === 'number')) {
      params[key] = searchParams[key];
    }
  });

  return params;
};

export default getSearchParams;
