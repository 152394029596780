import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { NotificationManager } from 'react-notifications';

import {
  listCitiesApi, getCityApi, addCityApi, editCityApi, deleteCityApi,
} from './api';
import { CountriesContext } from '../countries/Provider';
import { RegionsContext } from '../regions/Provider';
import getSearchParams from '../utils/getSearchParams';
import ParentsSearchParams from '../utils/ParentsSearchParams';
import getErrorMessage from '../utils/getErrorMessage';

const CitiesContext = React.createContext();

const CitiesProvider = ({ children }) => {
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useState({});
  const [cities, setCities] = useState([]);
  const [currentCity, setCurrentCity] = useState(null);

  const { clearFetchCountries } = useContext(CountriesContext);
  const { clearFetchRegions } = useContext(RegionsContext);

  const clearFetchCities = (params) => listCitiesApi(params);

  const fetchCities = async (params = null) => {
    const finalParams = params || getSearchParams(searchParams);

    try {
      const citiesData = await listCitiesApi(finalParams);

      setCities(citiesData.data.items);
      setCount(citiesData.data.count);
      setLoading(false);
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  const fetchCitiesWithParentsNames = async (params = null) => {
    const finalParams = params || getSearchParams(searchParams);

    try {
      const citiesData = await listCitiesApi(finalParams);
      const parentsSearchParams = new ParentsSearchParams(citiesData.data.items, ['countryId', 'regionId'])
        .getParentsSearchParams();
      const [countriesData, regionsData] = await Promise.all([
        clearFetchCountries(parentsSearchParams.countryId),
        clearFetchRegions(parentsSearchParams.regionId),
      ]);
      const countriesMap = ParentsSearchParams.getParentMap(countriesData.data.items);
      const regionsMap = ParentsSearchParams.getParentMap(regionsData.data.items);

      const citiesWithParentsNames = citiesData.data.items.map(
        (city) => ({
          ...city,
          countryName: countriesMap.get(city.countryId),
          regionName: regionsMap.get(city.regionId),
        }),
      );

      setCities(citiesWithParentsNames);
      setCount(citiesData.data.count);
      setLoading(false);
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  const getCity = async (id) => {
    try {
      setCurrentCity(null);

      const city = await getCityApi(id);

      setLoading(false);
      setCurrentCity(city.data);
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  const addCity = async (data, callback) => {
    try {
      await addCityApi(data);
      setLoading(false);

      if (callback && typeof callback === 'function') {
        callback();
      }
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  const editCity = async (id, data, callback) => {
    try {
      const city = await editCityApi(id, data);
      const index = cities.findIndex((item) => item.id === city.data.id);
      const newCities = [...cities];

      newCities.splice(index, 1, city.data);

      setCities(newCities);
      setLoading(false);

      if (callback && typeof callback === 'function') {
        callback();
      }
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  const deleteCity = async (id) => {
    try {
      await deleteCityApi(id);
      await fetchCities();
      setLoading(false);
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  return (
    <CitiesContext.Provider value={{
      count,
      loading,
      cities,
      searchParams,
      currentCity,
      setSearchParams,
      fetchCities,
      clearFetchCities,
      fetchCitiesWithParentsNames,
      getCity,
      addCity,
      editCity,
      deleteCity,
    }}
    >
      {children}
    </CitiesContext.Provider>
  );
};

CitiesProvider.propTypes = {
  children: PropTypes.object.isRequired,
};

export { CitiesProvider, CitiesContext };
