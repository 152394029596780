import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Spin, PageHeader } from 'antd';

import { TourOperatorsContext } from './Provider';
import Form from './Form';

const EditView = () => {
  const { id } = useParams();
  const {
    loading, currentTourOperator, getTourOperator, editTourOperator,
  } = useContext(TourOperatorsContext);

  useEffect(() => {
    getTourOperator(id);
  }, []);

  if (loading || !currentTourOperator) {
    return <Spin tip="Loading..." size="large" />;
  }

  return (
    <div>
      <PageHeader className="site-page-header" title="Редактировать тур оператора" />
      <Form edit loading={loading} initialValues={currentTourOperator} editTourOperator={editTourOperator} />
    </div>
  );
};

export default EditView;
