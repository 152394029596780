import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { NotificationManager } from 'react-notifications';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { CONTENT_URL } from '../../../constants';
import callApi from '../../../utils/callApi';
import getErrorMessage from '../../../utils/getErrorMessage';

const CDN_PUBLIC_URL = process.env.REACT_APP_CDN_PUBLIC_URL;
const textEditorImagesPathPrefix = 'files';

const FieldEditor = (props) => {
  const { addonBefore, form, field: { value, name } } = props;

  const getInitialState = (content) => {
    if (!content) return EditorState.createEmpty();

    const contentBlock = htmlToDraft(content);
    const contentState = contentBlock && ContentState.createFromBlockArray(contentBlock.contentBlocks);

    return contentBlock ? EditorState.createWithContent(contentState) : EditorState.createEmpty();
  };

  const uploadImageCallBack = async (image) => {
    try {
      const imageFormData = new FormData();

      imageFormData.append('image', image);

      const response = await callApi.post(`${CONTENT_URL}/images/text-editor`, imageFormData);

      if (response.status >= 400 && !response.data) {
        NotificationManager.error('Could not to upload image');

        return null;
      }

      const imageUrl = `${CDN_PUBLIC_URL}/${textEditorImagesPathPrefix}${response.data}`;

      return { data: { link: imageUrl } };
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));

      return null;
    }
  };

  const toolbar = {
    options: ['inline', 'blockType', 'list', 'link', 'image', 'remove', 'history'],
    inline: { options: ['bold', 'italic', 'underline', 'strikethrough'] },
    blockType: { options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote'] },
    list: { options: ['unordered', 'ordered'] },
    image: { uploadCallback: uploadImageCallBack, previewImage: true },
  };
  const initialEditorState = getInitialState(value);
  const [editorState, setEditorState] = useState(initialEditorState);

  const onEditorStateChange = (newEditorState) => {
    const html = draftToHtml(convertToRaw(newEditorState.getCurrentContent()));

    form.setFieldValue(name, html);

    return setEditorState(newEditorState);
  };

  return (
    <>
      {addonBefore && (
        <label className="form-check-label" htmlFor={addonBefore}>{addonBefore}</label>
      )}
      <Editor
        toolbar={toolbar}
        editorState={editorState}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        onEditorStateChange={onEditorStateChange}
      />
    </>
  );
};

FieldEditor.defaultProps = {
  addonBefore: '',
};

FieldEditor.propTypes = {
  addonBefore: PropTypes.string,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func,
  }).isRequired,
  field: PropTypes.shape({
    value: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
};

export default FieldEditor;
