import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NotificationManager } from 'react-notifications';

import {
  listMealsApi, getMealApi, addMealApi, editMealApi, deleteMealApi,
} from './api';
import getSearchParams from '../utils/getSearchParams';
import getErrorMessage from '../utils/getErrorMessage';

const MealsContext = React.createContext();

const MealsProvider = ({ children }) => {
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useState({});
  const [meals, setMeals] = useState([]);
  const [currentMeal, setCurrentMeal] = useState(null);

  const fetchMeals = async (params = null) => {
    const finalParams = params || getSearchParams(searchParams);

    try {
      const mealsData = await listMealsApi(finalParams);

      setMeals(mealsData.data.items);
      setCount(mealsData.data.count);
      setLoading(false);
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  const getMeal = async (id) => {
    try {
      setCurrentMeal(null);

      const meal = await getMealApi(id);

      setLoading(false);
      setCurrentMeal(meal.data);
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  const addMeal = async (data, callback) => {
    try {
      await addMealApi(data);
      setLoading(false);

      if (callback && typeof callback === 'function') {
        callback();
      }
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  const editMeal = async (id, data, callback) => {
    try {
      const meal = await editMealApi(id, data);
      const index = meals.findIndex((item) => item.id === meal.data.id);
      const newMeals = [...meals];

      newMeals.splice(index, 1, meal.data);

      setMeals(newMeals);
      setLoading(false);

      if (callback && typeof callback === 'function') {
        callback();
      }
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  const deleteMeal = async (id) => {
    try {
      await deleteMealApi(id);
      await fetchMeals();
      setLoading(false);
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  return (
    <MealsContext.Provider value={{
      count,
      loading,
      searchParams,
      meals,
      currentMeal,
      setSearchParams,
      fetchMeals,
      getMeal,
      addMeal,
      editMeal,
      deleteMeal,
    }}
    >
      {children}
    </MealsContext.Provider>
  );
};

MealsProvider.propTypes = {
  children: PropTypes.object.isRequired,
};

export { MealsProvider, MealsContext };
