import React, { useContext, useEffect } from 'react';
import { Spin, PageHeader, Pagination } from 'antd';

import { ReviewsContext } from './Provider';
import ReviewsTable from './Table';
import ReviewsTopPanel from './TopPanel';

const Reviews = () => {
  const {
    count, loading, searchParams, setSearchParams, fetchReviews,
  } = useContext(ReviewsContext);

  function onChangePagination(page, pageSize) {
    const skip = (page - 1) * pageSize;

    setSearchParams({ ...searchParams, skip });
  }

  useEffect(() => {
    fetchReviews();
  }, [searchParams]);

  if (loading) {
    return <Spin tip="Loading..." size="large" />;
  }

  return (
    <div style={{ margin: 30 }}>
      <PageHeader className="site-page-header" title="Отзывы" />
      <ReviewsTopPanel />
      <ReviewsTable />
      <Pagination
        showSizeChanger={false}
        current={(searchParams.skip / 20) + 1 || 1}
        defaultPageSize={20}
        total={count}
        onChange={onChangePagination}
      />
    </div>
  );
};

export default Reviews;
