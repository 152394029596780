/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import { Layout, Menu } from 'antd';
import { NavLink } from 'react-router-dom';
import { MENU_ITEMS } from '../constants';
import { AuthContext } from '../../auth/Provider';

const { Sider } = Layout;

const MainSider = () => {
  const { logout } = useContext(AuthContext);

  function renderMenuItems({ link, icon, title }) {
    return (
      <Menu.Item key={link} className="navItem">
        <NavLink to={link} activeClassName="active">
          {icon}
          {title}
        </NavLink>
      </Menu.Item>
    );
  }

  function renderSubMenu({
    key, icon, title, children,
  }) {
    return (
      <Menu.SubMenu
        key={key}
        title={(
          <span>
            {icon}
            <span>{title}</span>
          </span>
)}
      >
        {children.map(renderMenuItems)}
      </Menu.SubMenu>
    );
  }

  function renderMenu(item) {
    if (item.type === 'link') return renderMenuItems(item);
    if (item.type === 'subMenu') return renderSubMenu(item);

    return null;
  }


  return (
    <Sider>
      <div className="logo" />
      <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline">
        {MENU_ITEMS.map(renderMenu)}
        <Menu.Item key="logout" className="navItem" onClick={logout}>
          Logout
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default MainSider;
