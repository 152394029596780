import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Spin, PageHeader, Pagination } from 'antd';

import { HotelsContext } from './Provider';
import HotelsTable from './Table';
import HotelsTopPanel from './TopPanel';

const Hotels = () => {
  const location = useLocation();
  const {
    loading, count, searchParams, setSearchParams, fetchHotelsWithParentsNames,
  } = useContext(HotelsContext);

  useEffect(() => {
    if (location.state && location.state.cityId) {
      setSearchParams({ ...searchParams, cityId: location.state.cityId });
    }
  }, []);

  useEffect(() => {
    fetchHotelsWithParentsNames();
  }, [searchParams]);

  function onChangePagination(page, pageSize) {
    const skip = (page - 1) * pageSize;

    setSearchParams({ ...searchParams, skip });
  }

  if (loading) {
    return <Spin tip="Loading..." size="large" />;
  }

  return (
    <div style={{ margin: 30 }}>
      <PageHeader className="site-page-header" title="Отели" />
      <HotelsTopPanel />
      <HotelsTable />
      <Pagination
        showSizeChanger={false}
        current={(searchParams.skip / 20) + 1 || 1}
        defaultPageSize={20}
        total={count}
        onChange={onChangePagination}
      />
    </div>
  );
};

export default Hotels;
