import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Table, Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

import NoData from '../common/components/NoData';
import ActionButton from '../common/components/ActionButton';
import { StarsContext } from './Provider';

const StarsTable = () => {
  const history = useHistory();
  const { stars, deleteStar } = useContext(StarsContext);

  function renderEditButton(...args) {
    const [, rowItem] = args;

    return (
      <ActionButton
        type="primary"
        icon={<EditOutlined />}
        tooltip="Править"
        className="editAction"
        onClick={() => history.push(`/stars/${rowItem.id}/edit`)}
      />
    );
  }

  function renderDeleteButton(...args) {
    const [, rowItem] = args;
    const { id } = rowItem;
    const tooltip = 'Удалить';
    const deleteConfirm = 'Вы уверены, что хотите удалить эту категорию отеля?';

    return (
      <Popconfirm
        okText="Да"
        cancelText="Нет"
        placement="leftTop"
        title={deleteConfirm}
        onConfirm={() => deleteStar(id)}
      >
        <ActionButton
          type="danger"
          icon={<DeleteOutlined />}
          tooltip={tooltip}
          className="deleteAction"
        />
      </Popconfirm>
    );
  }

  const columns = [
    { dataIndex: 'name', title: 'Название', render: (title) => title.ru },
    { dataIndex: 'id', title: 'ID' },
    {
      width: 50, title: () => <EditOutlined />, dataIndex: '', key: 'EDIT', render: renderEditButton,
    },
    {
      width: 50, title: () => <DeleteOutlined />, dataIndex: '', key: 'DELETE', render: renderDeleteButton,
    },
  ];

  const tableHorizontalScroll = columns.reduce((sum, col) => (sum + col.width || 0), 0);

  if (!stars.length) return <NoData />;

  return (
    <Table
      columns={columns}
      rowKey="id"
      dataSource={stars}
      pagination={false}
      scroll={{ x: tableHorizontalScroll }}
    />
  );
};

export default StarsTable;
