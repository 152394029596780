import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Spin, PageHeader } from 'antd';

import { ReviewsContext } from './Provider';
import Form from './Form';

const EditView = () => {
  const { id } = useParams();
  const {
    loading, currentReview, getReview,
  } = useContext(ReviewsContext);

  useEffect(() => {
    getReview(id);
  }, []);

  if (loading || !currentReview) {
    return <Spin tip="Loading..." size="large" />;
  }

  return (
    <div style={{ margin: 30 }}>
      <PageHeader className="site-page-header" title="Редактировать отзыв" />
      <Form />
    </div>
  );
};

export default EditView;
