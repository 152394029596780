import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import {
  Button, Col, Row,
} from 'antd';

import InputField from '../common/components/formFields/InputField';

const Form = ({
  initialValues, loading, login,
}) => {
  const history = useHistory();

  function onSubmit(values) {
    return login(values, () => history.push('/'));
  }

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({
        handleSubmit,
      }) => (
        <Row>
          <Col span={20} offset={2}>
            <form onSubmit={handleSubmit}>
              <Row gutter={[8, 16]}>
                <Col>
                  <Field name="email" component={InputField} type="email" addonBefore="Э-почта" />
                </Col>

                <Col>
                  <Field name="password" component={InputField} type="password" addonBefore="Пароль" />
                </Col>
              </Row>

              <Button type="primary" htmlType="submit" disabled={loading}>
                Войти
              </Button>
            </form>
          </Col>
        </Row>
      )}
    </Formik>
  );
};

Form.defaultProps = {
  initialValues: {
    email: '',
    password: '',
  },
  loading: false,
  login: () => { },
};

Form.propTypes = {
  initialValues: PropTypes.shape({}),
  loading: PropTypes.bool,
  login: PropTypes.func,
};

export default Form;
