import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NotificationManager } from 'react-notifications';

import {
  listCountriesApi, getCountryApi, addCountryApi, editCountryApi, deleteCountryApi,
} from './api';
import getSearchParams from '../utils/getSearchParams';
import getErrorMessage from '../utils/getErrorMessage';

const CountriesContext = React.createContext();

const CountriesProvider = ({ children }) => {
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useState({});
  const [countries, setCountries] = useState([]);
  const [currentCountry, setCurrentCountry] = useState(null);

  const clearFetchCountries = (params) => listCountriesApi(params);

  const fetchCountries = async (params = null) => {
    const finalParams = params || getSearchParams(searchParams);

    try {
      const countriesData = await listCountriesApi(finalParams);

      setCountries(countriesData.data.items);
      setCount(countriesData.data.count);
      setLoading(false);
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  const getCountry = async (id) => {
    try {
      setCurrentCountry(null);

      const country = await getCountryApi(id);

      setLoading(false);
      setCurrentCountry(country.data);
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  const addCountry = async (data, callback) => {
    try {
      await addCountryApi(data);

      setLoading(false);

      if (callback && typeof callback === 'function') {
        callback();
      }
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  const editCountry = async (id, data, callback) => {
    try {
      await editCountryApi(id, data);
      await fetchCountries();

      if (callback && typeof callback === 'function') {
        callback();
      }
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  const deleteCountry = async (id) => {
    try {
      await deleteCountryApi(id);
      await fetchCountries();
      setLoading(false);
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  return (
    <CountriesContext.Provider value={{
      count,
      loading,
      searchParams,
      countries,
      currentCountry,
      setSearchParams,
      fetchCountries,
      clearFetchCountries,
      getCountry,
      addCountry,
      editCountry,
      deleteCountry,
    }}
    >
      {children}
    </CountriesContext.Provider>
  );
};

CountriesProvider.propTypes = {
  children: PropTypes.object.isRequired,
};

export { CountriesProvider, CountriesContext };
