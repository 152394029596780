import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'antd';

import AttributesTable from './AttributesTable';

const ROOMS_SERVICES_ID = '004ca6ba-9bec-4e7c-bb75-99a2ec622af2';

const Attributes = ({
  loading, values, services, fetchServices, setFieldValue, handleSubmit,
}) => {
  useEffect(() => {
    fetchServices({ limit: 0, groupId: ROOMS_SERVICES_ID });
  }, []);

  function getPreparedServices() {
    const preparedServices = [...services];

    if (!values.services || !preparedServices.length) {
      return preparedServices;
    }

    Object.entries(values.services).forEach(([service, value]) => {
      const index = preparedServices.findIndex((item) => item.key === service);

      if (index !== -1) {
        preparedServices[index].value = value;
      }
    });

    return preparedServices;
  }

  return (
    <form onSubmit={handleSubmit}>
      <AttributesTable
        services={getPreparedServices()}
        onChange={(key, value) => setFieldValue('services', { ...values.services, [key]: value })}
      />

      <Row gutter={[8, 16]}>
        <Col>
          <Button style={{ marginTop: '10px' }} type="primary" htmlType="submit" disabled={loading}>
            Сохранить
          </Button>
        </Col>
      </Row>
    </form>
  );
};

export default Attributes;

Attributes.defaultProps = {
  values: {
    services: {},
  },
};

Attributes.propTypes = {
  loading: PropTypes.bool.isRequired,
  values: PropTypes.shape({
    services: PropTypes.object,
  }),
  services: PropTypes.array.isRequired,
  fetchServices: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};
