import React, { useContext } from 'react';
import { PageHeader } from 'antd';

import { TourOperatorsContext } from './Provider';
import Form from './Form';

const AddView = () => {
  const { loading, addTourOperator } = useContext(TourOperatorsContext);

  return (
    <div>
      <PageHeader className="site-page-header" title="Добавить тур оператора" />
      <Form loading={loading} addTourOperator={addTourOperator} />
    </div>
  );
};

export default AddView;
