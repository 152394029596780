import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Col, Row, Select,
} from 'antd';
import _set from 'lodash.set';
import _get from 'lodash.get';

import ServicesTable from './ServicesTable';
import AddAttributeForm from './AddAttributeForm';

const { Option } = Select;

const Attributes = ({
  loading,
  history,
  values,
  servicesGroups,
  services,
  setFieldValue,
  handleSubmit,
  fetchServicesGroups,
  fetchServices,
}) => {
  const [selectedGroup, setGroup] = useState({});

  useEffect(() => {
    fetchServicesGroups({ limit: 0 });
  }, []);

  useEffect(() => {
    fetchServices({ groupId: selectedGroup.id, limit: 0 });
  }, [selectedGroup.id]);

  useEffect(() => {
    if (servicesGroups.length) {
      setGroup(servicesGroups[0]);
    }
  }, [servicesGroups]);

  const currentServices = _get(values.services, selectedGroup.key, {});

  const preItems = Object.keys(currentServices).map((key) => {
    const found = services.find((item) => item.key === key);

    if (found) {
      return {
        key,
        name: found.name.ru,
        possibleValues: found.possibleValues,
        value: currentServices[key],
      };
    }

    return null;
  });

  return (
    <form onSubmit={handleSubmit}>
      <Select
        onChange={(value) => {
          const found = servicesGroups.find((element) => element.id === value);

          setGroup(found);
        }}
        defaultValue={_get(selectedGroup, 'name.ru')}
        style={{ width: 180 }}
        value={_get(selectedGroup, 'name.ru')}
      >
        {servicesGroups.map((el) => (
          <Option value={el.id} key={el.id}>{el.name.ru}</Option>
        ))}
      </Select>

      <AddAttributeForm
        servicesValue={values.services || {}}
        servicesGroups={servicesGroups}
        services={services}
        setFieldValue={setFieldValue}
      />

      <ServicesTable
        items={preItems.filter((service) => service)}
        onChange={(key, value) => {
          const updatedServices = _set(values.services, `${[selectedGroup.key]}.${key}`, value);

          setFieldValue('services', updatedServices);
        }}
      />

      <Row gutter={[8, 16]}>
        <Col>
          <Button type="secondary" onClick={() => history.push('/hotels')}>
            Отменить
          </Button>
        </Col>

        <Col>
          <Button type="primary" htmlType="submit" disabled={loading}>
            Сохранить
          </Button>
        </Col>
      </Row>
    </form>
  );
};

export default Attributes;

Attributes.propTypes = {
  loading: PropTypes.bool.isRequired,
  history: PropTypes.any.isRequired,
  values: PropTypes.shape({
    services: PropTypes.shape({}),
  }).isRequired,
  servicesGroups: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  services: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  fetchServicesGroups: PropTypes.func.isRequired,
  fetchServices: PropTypes.func.isRequired,
};
