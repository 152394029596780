import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NotificationManager } from 'react-notifications';

import {
  listRoomsApi, getRoomApi, addRoomApi, editRoomApi, deleteRoomApi,
} from './api';
import getSearchParams from '../utils/getSearchParams';
import getErrorMessage from '../utils/getErrorMessage';

const RoomsContext = React.createContext();

const RoomsProvider = ({ children }) => {
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useState({});
  const [rooms, setRooms] = useState([]);
  const [currentRoom, setCurrentRoom] = useState(null);

  const fetchRooms = async (params = null) => {
    const finalParams = params || getSearchParams(searchParams);

    try {
      const roomsData = await listRoomsApi(finalParams);

      setRooms(roomsData.data.items);
      setCount(roomsData.data.count);
      setLoading(false);
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  const getRoom = async (id) => {
    try {
      setCurrentRoom(null);

      const room = await getRoomApi(id);

      setLoading(false);
      setCurrentRoom(room.data);
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  const addRoom = async (data, callback) => {
    try {
      const room = await addRoomApi(data);

      setLoading(false);

      if (callback && typeof callback === 'function') {
        callback();
      }

      return room.data;
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));

      return false;
    }
  };

  const editRoom = async (id, data, callback) => {
    try {
      await editRoomApi(id, data);

      if (callback && typeof callback === 'function') {
        callback();
      }
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  const deleteRoom = async (id, hotelId = null) => {
    try {
      await deleteRoomApi(id);
      await fetchRooms({ hotelId });
      setLoading(false);
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  return (
    <RoomsContext.Provider value={{
      count,
      loading,
      searchParams,
      rooms,
      currentRoom,
      setSearchParams,
      fetchRooms,
      getRoom,
      addRoom,
      editRoom,
      deleteRoom,
    }}
    >
      {children}
    </RoomsContext.Provider>
  );
};

RoomsProvider.propTypes = {
  children: PropTypes.object.isRequired,
};

export { RoomsProvider, RoomsContext };
