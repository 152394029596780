const getErrorsDescriptions = (array) => {
  let depth = 0;
  const errors = [];

  array.forEach((element) => {
    if (element.children.length) {
      depth += 1;

      return errors.push(getErrorsDescriptions(element.children));
    }

    return Object.values(element.constraints).forEach((error) => {
      errors.push(error);
    });
  });

  return errors.flat(depth);
};

const getErrorMessage = (error) => {
  if (error.response && error.response.data && error.response.data.message) {
    const { message } = error.response.data;

    if (Array.isArray(message)) {
      const errors = getErrorsDescriptions(message);

      return errors.join('; ');
    }

    return message;
  }

  return `${error}`;
};

export default getErrorMessage;
