import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NotificationManager } from 'react-notifications';

import {
  listServicesGroupsApi, getServicesGroupApi, addServicesGroupApi, editServicesGroupApi, deleteServicesGroupApi,
} from './api';
import getSearchParams from '../utils/getSearchParams';
import getErrorMessage from '../utils/getErrorMessage';

const ServicesGroupsContext = React.createContext();

const ServicesGroupsProvider = ({ children }) => {
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useState({});
  const [servicesGroups, setServicesGroups] = useState([]);
  const [currentServicesGroup, setCurrentServicesGroup] = useState(null);

  const clearFetchServicesGroups = (params) => listServicesGroupsApi(params);

  const fetchServicesGroups = async (params) => {
    const finalParams = params || getSearchParams(searchParams);

    try {
      const servicesGroupsData = await listServicesGroupsApi(finalParams);

      setServicesGroups(servicesGroupsData.data.items);
      setCount(servicesGroupsData.data.count);
      setLoading(false);
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  const getServicesGroup = async (id) => {
    try {
      setCurrentServicesGroup(null);

      const servicesGroup = await getServicesGroupApi(id);

      setLoading(false);
      setCurrentServicesGroup(servicesGroup.data);
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  const addServicesGroup = async (data, callback) => {
    try {
      await addServicesGroupApi(data);
      setLoading(false);

      if (callback && typeof callback === 'function') {
        callback();
      }
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  const editServicesGroup = async (id, data, callback) => {
    try {
      const servicesGroup = await editServicesGroupApi(id, data);
      const index = servicesGroups.findIndex((item) => item.id === servicesGroup.data.id);
      const newServicesGroups = [...servicesGroups];

      newServicesGroups.splice(index, 1, servicesGroup.data);

      setServicesGroups(newServicesGroups);
      setLoading(false);

      if (callback && typeof callback === 'function') {
        callback();
      }
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  const deleteServicesGroup = async (id) => {
    try {
      await deleteServicesGroupApi(id);
      await fetchServicesGroups();
      setLoading(false);
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  return (
    <ServicesGroupsContext.Provider value={{
      count,
      loading,
      searchParams,
      servicesGroups,
      currentServicesGroup,
      setSearchParams,
      fetchServicesGroups,
      clearFetchServicesGroups,
      getServicesGroup,
      addServicesGroup,
      editServicesGroup,
      deleteServicesGroup,
    }}
    >
      {children}
    </ServicesGroupsContext.Provider>
  );
};

ServicesGroupsProvider.propTypes = {
  children: PropTypes.object.isRequired,
};

export { ServicesGroupsProvider, ServicesGroupsContext };
