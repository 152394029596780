import React from 'react';
import PropTypes from 'prop-types';
import { Table, Popconfirm } from 'antd';
import {
  DeleteOutlined, EditOutlined,
} from '@ant-design/icons';

import ActionButton from '../../common/components/ActionButton';

const Reviews = ({ hotelId, reviews, deleteReview }) => {
  function renderEditButton(...args) {
    const [, rowItem] = args;

    return (
      <ActionButton
        type="primary"
        icon={<EditOutlined />}
        tooltip="Править"
        className="editAction"
        onClick={() => window.open(`/reviews/${rowItem.id}/edit`)}
      />
    );
  }

  function renderDeleteButton(...args) {
    const [, rowItem] = args;
    const { id } = rowItem;
    const tooltip = 'Удалить';
    const deleteConfirm = 'Вы уверены, что хотите удалить этот отзыв?';

    return (
      <Popconfirm
        okText="Да"
        cancelText="Нет"
        placement="leftTop"
        title={deleteConfirm}
        onConfirm={() => deleteReview(id, hotelId)}
      >
        <ActionButton
          type="danger"
          icon={<DeleteOutlined />}
          tooltip={tooltip}
          className="deleteAction"
        />
      </Popconfirm>
    );
  }

  const columns = [
    { dataIndex: 'content', title: 'Название', render: (content) => content.title },
    { dataIndex: 'id', title: 'ID' },
    {
      width: 50, title: () => <EditOutlined />, dataIndex: '', key: 'Edit', render: renderEditButton,
    },
    {
      width: 50, title: () => <DeleteOutlined />, dataIndex: '', key: 'Delete', render: renderDeleteButton,
    },
  ];

  const tableHorizontalScroll = columns.reduce((sum, col) => (sum + col.width || 0), 0);

  return (
    <Table
      columns={columns}
      rowKey="title"
      dataSource={reviews}
      pagination={false}
      scroll={{ x: tableHorizontalScroll }}
    />
  );
};

Reviews.propTypes = {
  hotelId: PropTypes.string.isRequired,
  reviews: PropTypes.array.isRequired,
  deleteReview: PropTypes.func.isRequired,
};

export default Reviews;
