import React, { useState, useContext } from 'react';
import { NotificationManager } from 'react-notifications';
import PropTypes from 'prop-types';

import {
  listHotelsApi, getHotelApi, addHotelApi, editHotelApi, deleteHotelApi, syncHotelWithTripAdvisorApi, syncHotelWithOtpuskApi,
} from './api';
import { CountriesContext } from '../countries/Provider';
import { CitiesContext } from '../cities/Provider';
import { StarsContext } from '../stars/Provider';
import getSearchParams from '../utils/getSearchParams';
import ParentsSearchParams from '../utils/ParentsSearchParams';
import getErrorMessage from '../utils/getErrorMessage';

const HotelsContext = React.createContext();

const HotelsProvider = ({ children }) => {
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useState({ sort: { createdAt: -1 } });
  const [hotels, setHotels] = useState([]);
  const [currentHotel, setCurrentHotel] = useState(null);

  const { clearFetchCountries } = useContext(CountriesContext);
  const { clearFetchCities } = useContext(CitiesContext);
  const { clearFetchStars } = useContext(StarsContext);

  const fetchHotels = async (params = null) => {
    const finalParams = params || getSearchParams(searchParams);

    try {
      const hotelsData = await listHotelsApi(finalParams);

      setCount(hotelsData.data.count);
      setHotels(hotelsData.data.items);
      setLoading(false);
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  const fetchHotelsWithParentsNames = async (params = null) => {
    const finalParams = params || getSearchParams(searchParams);

    try {
      const hotelsData = await listHotelsApi(finalParams);
      const parentsSearchParams = new ParentsSearchParams(hotelsData.data.items, ['countryId', 'cityId', 'starId'])
        .getParentsSearchParams();
      const [countriesData, citiesData, starsData] = await Promise.all([
        clearFetchCountries(parentsSearchParams.countryId),
        clearFetchCities(parentsSearchParams.cityId),
        clearFetchStars(parentsSearchParams.starId),
      ]);
      const countriesMap = ParentsSearchParams.getParentMap(countriesData.data.items);
      const citiesMap = ParentsSearchParams.getParentMap(citiesData.data.items);
      const starsMap = ParentsSearchParams.getParentMap(starsData.data.items);

      const hotelsWithParentsNames = hotelsData.data.items.map(
        (hotel) => ({
          ...hotel,
          countryName: countriesMap.get(hotel.countryId),
          cityName: citiesMap.get(hotel.cityId),
          starName: starsMap.get(hotel.starId),
        }),
      );

      setHotels(hotelsWithParentsNames);
      setCount(hotelsData.data.count);
      setLoading(false);
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  const getHotel = async (id) => {
    try {
      setCurrentHotel(null);

      const hotel = await getHotelApi(id);

      setLoading(false);
      setCurrentHotel(hotel.data);
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  const addHotel = async (data, callback) => {
    try {
      const hotel = await addHotelApi(data);

      setLoading(false);

      if (callback && typeof callback === 'function') {
        callback();
      }

      return hotel.data;
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));

      return false;
    }
  };

  const editHotel = async (id, data, callback) => {
    try {
      await editHotelApi(id, data);
      await fetchHotelsWithParentsNames();

      if (callback && typeof callback === 'function') {
        callback();
      }
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  const deleteHotel = async (id) => {
    try {
      await deleteHotelApi(id);
      await fetchHotels();
      setLoading(false);
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  const syncHotelWithTripAdvisor = async (data) => {
    try {
      const tripAdvisorHotelData = await syncHotelWithTripAdvisorApi(data);

      return tripAdvisorHotelData.data;
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));

      return false;
    }
  };

  const syncHotelWithOtpusk = async (data) => {
    try {
      const otpuskHotelData = await syncHotelWithOtpuskApi(data);

      return otpuskHotelData.data;
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));

      return false;
    }
  };

  return (
    <HotelsContext.Provider value={{
      count,
      loading,
      searchParams,
      hotels,
      currentHotel,
      setSearchParams,
      fetchHotels,
      fetchHotelsWithParentsNames,
      syncHotelWithTripAdvisor,
      syncHotelWithOtpusk,
      getHotel,
      addHotel,
      editHotel,
      deleteHotel,
    }}
    >
      {children}
    </HotelsContext.Provider>
  );
};

HotelsProvider.propTypes = {
  children: PropTypes.object.isRequired,
};

export { HotelsProvider, HotelsContext };
