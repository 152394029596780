import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Table, Popconfirm, Button } from 'antd';
import {
  DeleteOutlined, EditOutlined,
} from '@ant-design/icons';

import ActionButton from '../../common/components/ActionButton';
import AddRoomModal from './AddRoomModal';

const Rooms = ({ hotelId, rooms, deleteRoom }) => {
  const [modalVisible, setModalVisible] = useState(false);

  function renderEditButton(...args) {
    const [, rowItem] = args;

    return (
      <ActionButton
        type="primary"
        icon={<EditOutlined />}
        tooltip="Править"
        className="editAction"
        onClick={() => window.open(`/rooms/${rowItem.id}/edit`)}
      />
    );
  }

  function renderDeleteButton(...args) {
    const [, rowItem] = args;
    const { id } = rowItem;
    const tooltip = 'Удалить';
    const deleteConfirm = 'Вы уверены, что хотите удалить эту комнату?';

    return (
      <Popconfirm
        okText="Да"
        cancelText="Нет"
        placement="leftTop"
        title={deleteConfirm}
        onConfirm={() => deleteRoom(id, hotelId)}
      >
        <ActionButton
          type="danger"
          icon={<DeleteOutlined />}
          tooltip={tooltip}
          className="deleteAction"
        />
      </Popconfirm>
    );
  }

  const columns = [
    { dataIndex: 'title', title: 'Название', render: (title) => title.ru },
    { dataIndex: 'id', title: 'ID' },
    {
      width: 50, title: () => <EditOutlined />, dataIndex: '', key: 'Edit', render: renderEditButton,
    },
    {
      width: 50, title: () => <DeleteOutlined />, dataIndex: '', key: 'Delete', render: renderDeleteButton,
    },
  ];

  const tableHorizontalScroll = columns.reduce((sum, col) => (sum + col.width || 0), 0);

  return (
    <>
      <Button
        style={{ display: 'flex', marginBottom: '25px', marginLeft: 'auto' }}
        type="primary"
        onClick={() => setModalVisible(true)}
      >
        Добавить комнату
      </Button>

      <Table
        columns={columns}
        rowKey="title"
        dataSource={rooms}
        pagination={false}
        scroll={{ x: tableHorizontalScroll }}
      />

      <AddRoomModal
        hotelId={hotelId}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
      />
    </>
  );
};

Rooms.propTypes = {
  hotelId: PropTypes.string.isRequired,
  rooms: PropTypes.array.isRequired,
  deleteRoom: PropTypes.func.isRequired,
};

export default Rooms;
