import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Table, Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

import NoData from '../common/components/NoData';
import ActionButton from '../common/components/ActionButton';
import { ServicesContext } from './Provider';

const ServicesTable = () => {
  const history = useHistory();
  const { services, deleteService } = useContext(ServicesContext);

  function renderEditButton(...args) {
    const [, rowItem] = args;

    return (
      <ActionButton
        type="primary"
        icon={<EditOutlined />}
        tooltip="Править"
        className="editAction"
        onClick={() => history.push(`/services/${rowItem.id}/edit`)}
      />
    );
  }

  function renderDeleteButton(...args) {
    const [, rowItem] = args;
    const { id } = rowItem;
    const tooltip = 'Удалить';
    const deleteConfirm = 'Вы уверены, что хотите удалить эту сервис?';

    return (
      <Popconfirm
        okText="Да"
        cancelText="Нет"
        placement="leftTop"
        title={deleteConfirm}
        onConfirm={() => deleteService(id)}
      >
        <ActionButton
          type="danger"
          icon={<DeleteOutlined />}
          tooltip={tooltip}
          className="deleteAction"
        />
      </Popconfirm>
    );
  }

  const columns = [
    { dataIndex: 'name', title: 'Название', render: (title) => title.ru },
    { dataIndex: 'id', title: 'ID' },
    { dataIndex: 'key', title: 'Ключ' },
    { dataIndex: 'groupName', title: 'Группа' },
    { dataIndex: 'filter', title: 'Отображение в фильтрах', render: (value) => (value ? 'Да' : 'Нет') },
    {
      width: 50, title: () => <EditOutlined />, dataIndex: '', key: 'EDIT', render: renderEditButton,
    },
    {
      width: 50, title: () => <DeleteOutlined />, dataIndex: '', key: 'DELETE', render: renderDeleteButton,
    },
  ];

  const tableHorizontalScroll = columns.reduce((sum, col) => (sum + col.width || 0), 0);

  if (!services.length) return <NoData />;

  return (
    <Table
      columns={columns}
      rowKey="id"
      dataSource={services}
      pagination={false}
      scroll={{ x: tableHorizontalScroll }}
    />
  );
};

export default ServicesTable;
